import { GetProp, UploadProps } from "antd";

export const readFile = <T = any>(file) => {
  return new Promise<T>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = (e) => {
      const json: T = e?.target?.result
        ? JSON.parse(e.target.result.toString())
        : null;
      resolve(json);
    };
    fileReader.onerror = (error) => reject(error);
  });
};

export function getRandomNumber(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function generateRandomSlug() {
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  let slug = "";
  for (let i = 0; i < 3; i++) {
    slug += alphabet[Math.floor(Math.random() * alphabet.length)];
  }

  return slug;
}

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export const beforeUpload = (file: FileType) => {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg" ||
    file.type === "image/webp";
  if (!isJpgOrPng) {
    Error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    Error("Image must be smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};
