import { useEffect } from "react";
import useModules from "../../hooks/useModules";

export const Module = () => {
  const { fetchModuleData, getSlugFromUrl } = useModules();

  const teamSlug = getSlugFromUrl();

  function getDynamicLocalStorageItem() {
    const regex = /^sb-.*-auth-token$/;
    for (let i = 0; i < localStorage.length; i++) {
      const key: any = localStorage.key(i);
      if (regex.test(key)) {
        return localStorage.getItem(key);
      }
    }
    return null;
  }
  useEffect(() => {
    if (getDynamicLocalStorageItem()) fetchModuleData(teamSlug);
  }, [fetchModuleData, teamSlug]);

  return null;
};
