import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/common.json";
import de from "./locales/de/common.json";

const supportedLanguages = ["en", "de"];
const userLanguage = navigator.language.split("-")[0];
const selectedLanguage = supportedLanguages.includes(userLanguage)
  ? userLanguage
  : "en";

i18n.use(initReactI18next).init({
  lng: selectedLanguage,
  resources: {
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
  },
  supportedLngs: supportedLanguages,
  fallbackLng: supportedLanguages,
});

export default i18n;
