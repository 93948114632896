import { useTranslate } from "@refinedev/core";
import { Flex, Typography, Button } from "antd";
import JSConfetti from "js-confetti";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export function WelcomeMessage({ slug }: { slug: string }) {
  const navigate = useNavigate();
  const jsConfetti = useMemo(() => new JSConfetti(), []);

  const translsate = useTranslate();

  setTimeout(() => {
    jsConfetti.addConfetti();
  }, 500);
  // useEffect(() => {
  //   // const interval = setInterval(() => {
  //     //   jsConfetti.addConfetti();
  //     // }, 2000);
  //     // return () => clearInterval(interval);
  //     // jsConfetti.addConfetti();
  // }, [jsConfetti]);
  return (
    <Flex
      style={{
        height: "100%",
        width: "100%",
        justifyContent: "center",
        background: "rgba(255,255,255,0.8)",
        zIndex: 1000,
        alignItems: "center",
        top: 0,
        left: 0,
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <div style={{ maxWidth: 500, textAlign: "center" }}>
        <Typography.Title level={2}>
          {translsate("teams.team_creation_success_title")}
        </Typography.Title>

        <Typography.Title level={5}>
          {translsate("teams.team_creation_success")}
        </Typography.Title>
      </div>
      <Button type="primary" onClick={() => navigate(`/${slug}`)}>
        {translsate("teams.team_creation_success_button")}
      </Button>
    </Flex>
  );
}
