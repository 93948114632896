import { useTranslate } from "@refinedev/core";
import { Button, Flex, Typography } from "antd";
import JSConfetti from "js-confetti";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export function AccountSetupConfetti() {
  const navigate = useNavigate();
  const jsConfetti = useMemo(() => new JSConfetti(), []);
  const translate = useTranslate();
  setTimeout(() => {
    jsConfetti.addConfetti();
  }, 500);
  return (
    <Flex vertical align="center" justify="center" style={{ height: "100vh" }}>
      <div style={{ maxWidth: 500, textAlign: "center" }}>
        <Typography.Title level={2}>
          {translate("account.account_creation_success_title")}
        </Typography.Title>

        <Typography.Title level={5}>
          {translate("account.account_creation_success")}
        </Typography.Title>
      </div>
      <Button type="primary" onClick={() => navigate("/team/create")}>
        {translate("account.account_creation_success_button")}
      </Button>
    </Flex>
  );
}
