import { useTranslate } from "@refinedev/core";
import { Flex, Typography } from "antd";
import React, { useMemo } from "react";
import { Images } from "../../components/Images";
import CreateSteper from "../../components/steps/createTeam";
import { WelcomeMessage } from "../../components/steps/WelcomeMessage";
import useTeam from "../../hooks/useTeam";
import styles from "./style.module.css";

export default function CreateTeam() {
  const [newTeam, setNewTeam] = React.useState<any>();
  const translate = useTranslate();

  const { allTeams } = useTeam();
  const hasTeam = useMemo(
    () => allTeams?.some((team) => !team.personal_account),
    [allTeams]
  );
  return (
    <Flex className={styles.container}>
      <div className={styles.stepperContainer}>
        {allTeams && !newTeam?.slug && (
          <CreateSteper
            newTeam={newTeam}
            setNewTeam={setNewTeam}
            isFirstTeam={hasTeam}
          />
        )}
        {newTeam?.slug && <WelcomeMessage slug={newTeam?.slug} />}
      </div>
      <div className={styles.imgContainer}>
        <img src={Images.TeamCover} />
      </div>
      {!newTeam?.slug && (
        <div className={styles.stripHeader}>
          <Typography.Title>
            {translate("teams.create_new_team")}
          </Typography.Title>
        </div>
      )}
    </Flex>
  );
}
