import { useSelect } from "@refinedev/antd";
import { Select } from "antd";

type Props = {
  value?: any[];
  onChange?: (value: any[]) => void;
};
export default function GroupsAutoComplete({ value = [], onChange }: Props) {
  const { queryResult: results } = useSelect({
    resource: "tags",
    optionLabel: "name",
    optionValue: "id",
  });
  return (
    <div>
      <Select
        style={{
          width: "100%",
          minWidth: "200px",
          marginRight: "1rem",
        }}
        showSearch
        allowClear
        mode="multiple"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        placeholder="Filter by group"
        options={results.data?.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))}
        value={value ?? []}
        onChange={onChange}
      />
    </div>
  );
}
