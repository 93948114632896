import { Radio, Space, Typography } from "antd";
import { RadioProps } from "antd/lib";
import Styles from "./index.module.css";

type Props = { title?: string; description?: string } & RadioProps;
export default function CustomRadio({ title, description, ...props }: Props) {
  return (
    <div className={"customRadio"}>
      <Radio {...props} style={{ width: "100%" }} className={Styles.radio}>
        <Space direction="vertical">
          {title && (
            <Typography.Title
              className={Styles.title}
              disabled={props.disabled}
              level={4}
            >
              {title}
            </Typography.Title>
          )}
          {description && (
            <Typography.Text disabled={props.disabled}>
              {description}
            </Typography.Text>
          )}
        </Space>
        {props.children}
      </Radio>
    </div>
  );
}
