import React from "react";
import { IMedia } from "../interfaces/general.interface";
import { supabaseClient } from "../utility";

export default function useFile() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [previewURL, setPreviewURL] = React.useState<
    | string
    | {
        blob: Blob;
        file: File;
        base64: string;
      }
    | null
  >(null);

  const randomString = React.useMemo(
    () => (new Date() + Math.random().toString(36)).substring(7),
    []
  );
  const getFileDataURL = React.useCallback(
    async (
      fileObj?: IMedia | null,
      returnAsFile?: boolean
    ): Promise<
      | string
      | {
          blob: Blob;
          file: File;
          base64: string;
        }
      | null
    > => {
      try {
        if (!fileObj?.fullPath) {
          throw new Error("Dateipfad nicht gefunden.");
        }
        setIsLoading(true);
        const { data, error } = await supabaseClient.storage
          .from(fileObj.fullPath?.split("/").slice(0, -1).join("/") ?? "")
          .download(fileObj.name + "?cache=" + randomString);
        if (error) {
          console.error("Fehler beim Herunterladen der Datei:", error);
          return null;
        }
        const file = data;
        if (!file) {
          console.error("Datei nicht gefunden.");
          return null;
        }

        const reader = new FileReader();

        reader.readAsDataURL(file);
        setIsLoading(false);
        // Überprüfen, ob der MIME-Typ ein Bild ist
        const mimeType = file.type;
        if (!mimeType || !mimeType.startsWith("image/")) {
          return null;
        }

        const base64 = new Promise<string | null>((resolve, reject) => {
          reader.onload = () => {
            const result = reader.result as string;
            resolve(result);
          };
          reader.onerror = (error) => {
            console.error("Fehler beim Lesen der Datei:", error);
            reject(null);
          };
        });
        const awaitedBase64 = await base64;

        if (returnAsFile && awaitedBase64) {
          return {
            file: new File([file], fileObj?.name ?? ""),
            blob: file,
            base64: awaitedBase64,
          };
        }

        return base64;
      } catch (error) {
        setIsLoading(false);
        console.error("Fehler beim Herunterladen der Datei:", error);
        return null;
      }
    },
    [randomString]
  );

  const removeFile = async (file: string[]) => {
    setIsLoading(true);
    try {
      const { data, error } = await supabaseClient.storage
        .from("files")
        .remove(file);
      if (error) {
        throw error;
      }
      setIsLoading(false);
      return data;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  return { getFileDataURL, setPreviewURL, removeFile, previewURL, isLoading };
}
