import { PropsWithChildren } from "react";
import AutomationCreateUpdate from "../../components/automations/AutomationCreateUpdate";

export default function AutomationsCreate({ children }: PropsWithChildren) {
  return (
    <>
      <AutomationCreateUpdate />
      {children}
    </>
  );
}
