import { dataProvider } from "@refinedev/supabase";
import { IMultilingual, LanguageCode } from "../interfaces/general.interface";
import { supabaseClient } from "./supabaseClient";

export const sortPipelines = (array: any[]) => {
  const sortedArray = array?.sort((a: any, b: any) => {
    if (a.parent !== null && b.parent !== null) {
      return a.order - b.order;
    }
    if (a.parent === null && b.parent === null) {
      return a.order - b.order;
    }
    if (a.parent === null) return -1;
    return 0;
  });
  return sortedArray ?? array;
};

export const triggerExport = async (item: any) => {
  try {
    const getList = dataProvider(supabaseClient).getOne({
      resource: "pipelines",
      id: item.id,
      meta: {
        select: "*, pipeline_stages!pipeline_stages_pipeline_fkey(*)",
      },
    });
    const listForExport: any = (await getList).data;

    delete listForExport.created_at;

    const pipeline_stages = listForExport.pipeline_stages.map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ created_at, pipeline, ...rest }) => rest
    );

    const formatted = { ...listForExport, pipeline_stages };

    const jsonProducts = JSON.stringify(formatted);

    const blob = new Blob([jsonProducts], { type: "application/json" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `pipeline_${(listForExport.name ?? "")
      .trim()
      .replace(" ", "-")}_${new Date().toLocaleDateString(
      "de-DE"
    )}_${new Date().toLocaleTimeString("de-DE")}.json`;
    link.click();
  } catch (error) {
    console.error("Error exporting data:", error);
  }
};

export const textFromLang = (text: IMultilingual, lang: LanguageCode) => {
  return text[lang] ?? text[Object.keys(text)[0]];
};
