import { List, useTable } from "@refinedev/antd";
import { BaseKey, useDeleteMany, useTranslate } from "@refinedev/core";
import { Button, Popconfirm, Spin, Table } from "antd";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../contexts/ContextProvider";

const SubscriptionProductsList: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const navigate = useNavigate();

  const { currentTeam } = useUser();

  const { tableProps, tableQueryResult } = useTable({
    resource: "subscription_products",
    sorters: {
      initial: [
        {
          field: "id",
          order: "desc",
        },
      ],
    },
    syncWithLocation: true,
    filters: {
      permanent: [
        {
          field: "account",
          value:
            currentTeam?.account_id ?? "00000000-0000-0000-0000-000000000000",
          operator: "eq",
        },
      ],
    },
  });

  const { mutate } = useDeleteMany();

  const handleDeleteBulk = () => {
    mutate({
      resource: "subscription_products",
      ids: selectedRowKeys as BaseKey[],
    });
    setSelectedRowKeys([]);
  };

  const translate = useTranslate();

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [tableQueryResult.data?.data]);
  const handleRowClick = (event: any, id: any) => {
    if (event.target.className !== "ant-checkbox-input") navigate("show/" + id);
  };

  if (!currentTeam || !currentTeam?.account_id || tableProps.loading) {
    return <Spin />;
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <>
      <List
        title={translate("subscription_products.list.title")}
        breadcrumb={false}
        headerButtons={({ defaultButtons }) => (
          <>
            {selectedRowKeys.length != 0 && (
              <Popconfirm
                title={translate("subscription_products.buttons.delete.title")}
                description={translate(
                  "subscription_products.buttons.delete.description"
                )}
                onConfirm={handleDeleteBulk}
                okText={translate("buttons.yes", "Yes")}
                cancelText={translate("buttons.no", "No")}
              >
                <Button type="primary" danger>
                  {translate("subscription_products.buttons.delete.selected")}
                </Button>
              </Popconfirm>
            )}
            {defaultButtons}
          </>
        )}
      >
        <Table
          {...tableProps}
          rowKey={(record) => record.id ?? ""}
          rowClassName={"clickableRow"}
          loading={currentTeam === undefined}
          onRow={(record) => {
            return {
              onClick: (event) => handleRowClick(event, record.id),
            };
          }}
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
          }}
          rowSelection={rowSelection}
        >
          <Table.Column
            dataIndex="title"
            title={translate("subscription_products.fields.title")}
          />

          <Table.Column
            title={translate("subscription_products.fields.description")}
            dataIndex="description"
          />
        </Table>
      </List>
      {children}
    </>
  );
};
export default SubscriptionProductsList;
