import { List, useTable } from "@refinedev/antd";
import { Spin } from "antd";
import { PropsWithChildren } from "react";
import AutomationListPage from "../../components/automations/list";
import { useUser } from "../../contexts/ContextProvider";

export default function AutomationsList({ children }: PropsWithChildren) {
  const { currentTeam } = useUser();
  const { tableProps, tableQueryResult } = useTable({
    resource: "automations",
    sorters: { initial: [{ field: "created_at", order: "desc" }] },
    filters: {
      initial: [
        {
          field: "account",
          value: currentTeam?.account_id,
          operator: "eq",
        },
      ],
    },
    meta: {
      select: `*`,
    },
  });
  if (tableProps.loading) return <Spin />;
  return (
    <>
      <List breadcrumb={false}>
        <AutomationListPage
          tableProps={tableProps}
          data={tableQueryResult.data}
        />
      </List>
      {children}
    </>
  );
}
