import { MailOutlined } from "@ant-design/icons";
import { RegisterFormTypes, useRegister } from "@refinedev/core";
import { Button, Card, Col, Layout, Row, Space, Spin, Typography } from "antd";
import { CSSProperties, useEffect, useState } from "react";

export const layoutStyles: CSSProperties = {
  background: "url('/images/authpage-login.jpg')",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  position: "absolute",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px",
};

export const containerStyles: CSSProperties = {
  maxWidth: "500px",
  margin: "auto",
  padding: "32px",
  boxShadow:
    "0px 2px 4px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px rgba(0, 0, 0, 0.03)",
  textAlign: "center",
};

function getEmailFromQuery() {
  const queryString = window.location.search;
  const urlParams = queryString.split("=")[1];
  return urlParams;
}

export default function VerifyPage() {
  const { mutate: register } = useRegister<RegisterFormTypes>();
  const [email, setEmail] = useState<string | null>();

  useEffect(() => {
    const emailParam = getEmailFromQuery();
    if (emailParam != null) {
      setEmail(decodeURIComponent(emailParam));
    }
  }, []);

  function handleResendEmail() {
    if (email) {
      register({ email });
    }
  }

  if (!email) return <Spin />;

  return (
    <Layout style={layoutStyles}>
      <Row
        justify="center"
        align={"middle"}
        style={{
          padding: "16px 0",
          minHeight: "100dvh",
          paddingTop: "16px",
        }}
      >
        <Col xs={24}>
          <Card style={containerStyles}>
            <MailOutlined style={{ fontSize: "3em" }} />
            <Typography.Title>Verify your email</Typography.Title>
            <Typography.Paragraph>
              We have sent you an email to{" "}
              <b style={{ whiteSpace: "nowrap" }}>{email}</b> with a link to
              verify your email address. Please check your inbox and click on
              the link to continue.
            </Typography.Paragraph>
            <Typography.Paragraph>
              If you don't see the email, please check your spam folder.
            </Typography.Paragraph>

            <Space>
              <Button type="primary" onClick={() => handleResendEmail()}>
                Resend email
              </Button>
              <Button type="link" href="/login">
                Back to home
              </Button>
            </Space>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}
