import { AntDValueEditor, QueryBuilderAntD } from "@react-querybuilder/antd";
import { useTranslate } from "@refinedev/core";
import React, { useCallback, useEffect, useMemo } from "react";
import QueryBuilder, {
  CombinatorSelectorProps,
  defaultOperators,
  Field,
  getOption,
  RuleGroupType,
  RuleType,
  ValueEditorProps,
} from "react-querybuilder";
import { parseJsonLogic } from "react-querybuilder/dist/parseJsonLogic";
import { useUser } from "../../../contexts/ContextProvider";
import { supabaseClient } from "../../../utility/supabaseClient";

type Props = {
  handleChange: (value: any) => void;
  initialQuery?: RuleGroupType;
  showInWeek?: boolean;
};
const defaulQuery: RuleGroupType = { combinator: "and", rules: [] };

export const CustomValueEditor = (props: ValueEditorProps) => {
  const translate = useTranslate();
  if (["within", "inXDays"].includes(props.rule.operator)) {
    return (
      <AntDValueEditor
        {...props}
        type={"text"}
        inputType={"number"}
        fieldData={{
          ...props.fieldData,
          inputType: "number",
          placeholder: translate("common.days", "Days"),
        }}
      />
    );
  }
  return <AntDValueEditor {...props} />;
};

const CombinatorSelector = (props: CombinatorSelectorProps) => {
  // if (props.value?.toLocaleLowerCase() == "and") {
  //   props.handleOnChange("or");
  // }
  if (props.level == 0) {
    return (
      <div className={props.className} title={props.title}>
        {getOption(props.options, "and")?.label}
      </div>
    );
  }
  return (
    <div className={props.className} title={props.title}>
      {getOption(props.options, "or")?.label}
    </div>
  );
};

export default function CustomQueryBuilder({
  handleChange,
  initialQuery,
  showInWeek = false,
}: Props) {
  const [fields, setFields] = React.useState();
  const [query, setQuery] = React.useState(() =>
    initialQuery
      ? "combinator" in initialQuery || "rules" in initialQuery
        ? initialQuery
        : parseJsonLogic(initialQuery)
      : defaulQuery
  );
  const { currentTeam } = useUser();
  const inputTypeMap = useMemo(
    () => ({
      bigint: "number",
      text: "text",
      "timestamp with time zone": "datetime-local",
      "USER-DEFINED": "text",
      uuid: "text",
      jsonb: "text",
      date: "date",
    }),
    []
  );
  const validator = (r: RuleType) => !!r.value;

  const getTableColumns = useCallback(async () => {
    const data = await supabaseClient.rpc("get_contacts_table_columns", {
      p_account: currentTeam?.account_id,
    });
    const formFields = data?.data.map((item) => ({
      name: `contacts.${item.column_name}`,
      label: item.label
        ? item.label
        : item.column_name
            .replace(/custom_fields->>/g, "")
            .replace(/_/g, " ")
            .replace(/\b\w/g, (c) => c.toUpperCase()),
      inputType: inputTypeMap[item.data_type] || "text",
      placeholder: `Enter ${item.column_name.replace(/_/g, " ")}`,
      validator,
    }));
    setFields(formFields);
  }, [currentTeam?.account_id, inputTypeMap]);

  useEffect(() => {
    if (fields === undefined) {
      getTableColumns();
    }
  }, [fields, getTableColumns]);

  const getOperators = (
    _fieldName: string,
    { fieldData }: { fieldData: Field }
  ) => {
    switch (fieldData.inputType) {
      case "text":
        return [
          { name: "=", label: "is" },
          { name: "!=", label: "is not" },
          ...defaultOperators.filter((op) =>
            [
              "contains",
              "beginsWith",
              "endsWith",
              "doesNotContain",
              "doesNotBeginWith",
              "doesNotEndWith",
              "null",
              "notNull",
              "in",
              "notIn",
            ].includes(op.name)
          ),
        ];
      case "number":
        return [
          ...defaultOperators.filter((op) => ["=", "!="].includes(op.name)),
          { name: "<", label: "less than" },
          { name: "<=", label: "less than or equal to" },
          { name: ">", label: "greater than" },
          { name: ">=", label: "greater than or equal to" },
          ...defaultOperators.filter((op) =>
            ["null", "notNull"].includes(op.name)
          ),
        ];
      case "datetime-local":
      case "date":
        return [
          { name: "=", label: "on" },
          { name: "!=", label: "not on" },
          { name: "<", label: "before" },
          { name: "<=", label: "on or before" },
          { name: ">", label: "after" },
          { name: ">=", label: "on or after" },
          ...(showInWeek
            ? [
                { name: "within", label: "within" },
                { name: "inXDays", label: "inXDays" },
              ]
            : []),
          ...defaultOperators.filter((op) =>
            ["null", "notNull"].includes(op.name)
          ),
        ];
    }
    return defaultOperators;
  };

  if (!fields) {
    return null;
  }
  return (
    <div>
      <QueryBuilderAntD>
        <QueryBuilder
          fields={fields}
          query={query}
          onQueryChange={(qr) => {
            setQuery(qr);
            handleChange(qr);
          }}
          enableMountQueryChange={false}
          getOperators={getOperators}
          controlElements={{
            valueEditor: CustomValueEditor,
            // addGroupAction: () => {
            //   return null;
            // },
            combinatorSelector: CombinatorSelector,
          }}
          showShiftActions
        />
      </QueryBuilderAntD>
    </div>
  );
}
