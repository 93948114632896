export function findParentNode(nodes, targetNode, parent = null) {
  for (const node of nodes) {
    if (node.id == targetNode.id) {
      return parent;
    }
    if (node.children) {
      const result = findParentNode(node.children, targetNode, node);
      return result;
    }
  }
  return null;
}

export function editNode(nodes, targetNode, value) {
  for (const node of nodes) {
    if (node.id == targetNode.id) {
      Object.assign(node, value);
      return true;
    }
    if (node.children && node.children.length > 0) {
      const found = editNode(node.children, node, value);
      if (found) return true; // Exit if the node was found in the children
    }
  }
}
