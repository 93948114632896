import { List } from "@refinedev/antd";
import { useNotification, useTranslate } from "@refinedev/core";
import { Button, Popconfirm, Table } from "antd";
import { TableRowSelection } from "antd/es/table/interface";
import { PropsWithChildren, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { supabaseClient } from "../../utility";

const TeamMembersList: React.FC<PropsWithChildren> = () => {
  const { teamMembers, fetchTeamMembers, currentTeam } = useUser();
  const { open } = useNotification();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const translate = useTranslate();

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection: TableRowSelection<any> = {
    type: "radio",
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.account_role === "owner" && record.is_primary_owner,
    }),
  };
  const removeMemberFromTeam = async () => {
    const data = await supabaseClient.rpc("remove_account_member", {
      user_id: selectedRowKeys[0],
      account_id: currentTeam?.account_id,
    });
    if (data.status != 204) {
      open?.({
        description: translate("alerts.submitError.title"),
        message: translate("alerts.submitError.defaultMessage"),
        type: "error",
      });
    } else {
      open?.({
        description: translate("notifications.success"),
        message: translate("notifications.editSuccess"),
        type: "success",
      });
      fetchTeamMembers();
    }
  };
  return (
    <List
      title={translate("teams.members.title")}
      headerButtons={
        <>
          {selectedRowKeys.length == 1 && (
            <Popconfirm
              title={translate("teams.members.title")}
              description={translate("teams.members.description")}
              placement="leftBottom"
              onConfirm={() => removeMemberFromTeam()}
              okText={translate("buttons.yes")}
              cancelText={translate("buttons.no")}
            >
              <Button danger>{translate("teams.members.delete")}</Button>
            </Popconfirm>
          )}
        </>
      }
    >
      <Table
        dataSource={teamMembers}
        rowClassName={"clickableRow"}
        rowSelection={rowSelection}
        columns={[
          {
            title: translate("teams.members.name"),
            dataIndex: "name",
            key: "name",
          },
          {
            title: translate("teams.members.account_role"),
            dataIndex: "account_role",
            key: "account_role",
          },
          {
            title: translate("teams.members.email"),
            dataIndex: "email",
            key: "email",
          },
        ]}
        rowKey={(record) => record.user_id}
      />
    </List>
  );
};

export default TeamMembersList;
