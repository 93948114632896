import { useTranslate } from "@refinedev/core";
import { Button, Form, Space } from "antd";
import CustomInput from "../form/customInput";

type Props = {
  handleNext: (data) => void;
  disabled?: boolean;
  handlePrev?: () => void;
  handleFinish?: ({ teamName }: { teamName: string }) => void;
  isFirstTeam?: boolean;
};
export default function FirstStep({
  handleNext,
  disabled,
  handlePrev,
  handleFinish,
  isFirstTeam,
}: Props) {
  const translate = useTranslate();
  const [form] = Form.useForm();
  const isFilled = Form.useWatch("name", form);

  const onFinish = async (values: any) => {
    handlePrev || isFirstTeam
      ? handleFinish?.({ teamName: values.name })
      : handleNext(values.name);
  };
  return (
    <Form form={form} onFinish={onFinish} disabled>
      <Form.Item
        name="name"
        rules={[
          { required: true, message: translate("validation.field_required") },
        ]}
      >
        <CustomInput
          disabled={disabled}
          placeholder={translate("global.enter_text_here")}
          label={translate("teams.registration_label")}
        />
      </Form.Item>
      <Form.Item>
        <Space>
          {handlePrev && (
            <Button disabled={disabled} onClick={handlePrev}>
              {translate("buttons.previous")}
            </Button>
          )}
          <Button
            style={{ marginLeft: "0.3rem" }}
            disabled={disabled || !isFilled}
            type="primary"
            htmlType="submit"
          >
            {handlePrev || isFirstTeam
              ? translate("teams.create_team_button")
              : translate("buttons.next")}
          </Button>
          {isFirstTeam && (
            <Button disabled={false} onClick={() => (location.href = "/")}>
              Cancel
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
}
