import React, { PropsWithChildren, useState } from "react";

import {
  useDelete,
  useNavigation,
  useShow,
  useTranslate,
} from "@refinedev/core";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "@refinedev/antd";
import {
  Button,
  Card,
  Collapse,
  Drawer,
  Flex,
  Form,
  Input,
  Popconfirm,
  Space,
  Spin,
  Typography,
} from "antd";
import { CollapseProps } from "antd/lib";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { SingleElementForm, Text } from "../../../components";
import SinglePhase from "../../../components/shop/single-phase";
import { useUser } from "../../../contexts/ContextProvider";
import { BillingType } from "../../../interfaces/phase.interface";

const SubscriptionsShow: React.FC<PropsWithChildren> = ({ children }) => {
  const [activeForm, setActiveForm] = useState<"title" | "description">();

  const params = useParams();
  const { currentTeam } = useUser();
  const { list } = useNavigation();
  const { mutate: deleteMutation } = useDelete();

  const { queryResult } = useShow({
    resource: "subscriptions",
    id: params.subscriptionId,
  });

  const { formProps, form } = useForm({
    action: "edit",
    redirect: false,
    autoSave: {
      enabled: true,
      invalidateOnUnmount: true,
    },
    onMutationSuccess: () => {
      queryResult.refetch();
    },
    invalidates: ["all"],
    mutationMode: "pessimistic",
  });
  const translate = useTranslate();

  const closeModal = () => {
    list("subscriptions", "replace");
  };

  const { data, isLoading, isError } = queryResult;
  if (isError) {
    closeModal();
    return null;
  }
  if (isLoading && !currentTeam?.account_id) {
    return (
      <Drawer
        open
        styles={{
          body: {
            background: "#f5f5f5",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
        className="entityDrawer"
      >
        <Spin />
      </Drawer>
    );
  }

  const { id, title, phases, created_at } = data?.data ?? {};
  const items: CollapseProps["items"] = phases?.map(
    (item: any, index: number) => {
      return {
        key: (index + 1).toString(), // key should be a string

        label: (
          <Typography.Link>
            {dayjs(item.duration.from).format(translate("global.date_format"))}
            {" - "}
            {item.duration.to == BillingType.Forever
              ? (translate(
                  "subscription_products.fields.billing_type." +
                    BillingType.Forever
                ) as string)
              : dayjs(item.duration.to).format(translate("global.date_format"))}
          </Typography.Link>
        ),
        children: (
          <SinglePhase
            handleChange={(phase) => handleChangesOnPhases(phase, index)}
            handlePhaseDelete={() => handlePhaseDelete(index)}
            data={item}
            isSubscription
          />
        ),
      };
    }
  );
  function handlePhaseDelete(index: number) {
    const changedPhase = phases.filter(
      (_item, indexFilter) => index != indexFilter
    );
    form.setFieldValue("phases", changedPhase);
    form.submit();
  }
  function handleChangesOnPhases(phase: any, index: number) {
    const changedPhase = phases.map((item, indexPhase) => {
      if (indexPhase == index) {
        item = phase;
      }
      return item;
    });

    form.setFieldValue("phases", changedPhase);
    form.submit();
  }
  const handleAddNewPhase = () => {
    const newPhase = [
      ...phases,
      {
        key: 1,
        name: "Best deal ever!",
        invoice: {
          unit: "month",
          value: 3,
        },
        duration: {
          to: dayjs().format("YYYY-MM-DD"),
          from: dayjs().format("YYYY-MM-DD"),
          invoice: {
            unit: "day",
            value: 1,
          },
        },
        product_items: [],
      },
    ];
    form.setFieldValue("phases", newPhase);
    form.submit();
  };
  return (
    <>
      <Drawer
        open
        onClose={() => closeModal()}
        title={translate("subscriptions.show.title")}
        className="entityDrawer"
      >
        <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
          <Card
            title={
              <>
                <Text>{translate("subscription_products.show.basic")}</Text>
              </>
            }
          >
            <SingleElementForm
              state={
                activeForm && activeForm === "title"
                  ? "form"
                  : title
                  ? "view"
                  : "empty"
              }
              itemProps={{
                name: "title",
                label: translate("subscription_products.fields.title"),
                initialValue: title,
              }}
              useFormProps={{
                resource: "subscriptions",
                id: id,
              }}
              view={<Text>{title}</Text>}
              onClick={() => setActiveForm("title")}
              onUpdate={() => setActiveForm(undefined)}
              onCancel={() => setActiveForm(undefined)}
            >
              <Input />
            </SingleElementForm>
          </Card>
          <Card
            title={
              <>
                <Text>{translate("subscriptions.show.subscription")}: </Text>
                <Typography.Text style={{ fontWeight: 300 }}>
                  {title}
                </Typography.Text>
              </>
            }
            actions={[
              <Button
                style={{ width: "95%" }}
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleAddNewPhase}
              >
                {translate("subscription_products.list.add")}
              </Button>,
            ]}
          >
            <Form {...formProps} layout="vertical">
              <Form.Item name={"phases"} noStyle>
                <Input type="hidden" />
              </Form.Item>
              <Collapse
                items={items}
                defaultActiveKey={["1"]}
                expandIconPosition="end"
                bordered={false}
              />
            </Form>
          </Card>
          <Flex justify="space-between">
            <Text className="ant-text tertiary">
              {translate("subscription_products.fields.created_at")}{" "}
              {dayjs(created_at).format(translate("page.settings.date_format"))}{" "}
              {translate("page.settings.clock")}
            </Text>

            <Popconfirm
              title={translate("subscriptions.buttons.delete.title")}
              description={translate(
                "subscriptions.buttons.delete.description"
              )}
              onConfirm={() => {
                if (id) {
                  deleteMutation(
                    {
                      id,
                      resource: "subscriptions",
                    },
                    {
                      onSuccess: () => closeModal(),
                    }
                  );
                }
              }}
              okText={translate("buttons.yes", "Yes")}
              cancelText={translate("buttons.no", "No")}
            >
              <Button type="link" danger icon={<DeleteOutlined />}>
                {translate("subscriptions.buttons.delete.title")}
              </Button>
            </Popconfirm>
          </Flex>
        </Space>
      </Drawer>
      {children}
    </>
  );
};
export default SubscriptionsShow;
