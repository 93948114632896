import { ColorPicker, ColorPickerProps } from "antd";

const presetColors = [
  "#d1d5db",
  "#6b7280",
  "#374151",
  "#fca5a5",
  "#ef4444",
  "#b91c1c",
  "#fcd34d",
  "#f59e0b",
  "#b45309",
  "#bef264",
  "#84cc16",
  "#4d7c0f",
  "#5eead4",
  "#14b8a6",
  "#0f766e",
  "#67e8f9",
  "#06b6d4",
  "#0e7490",
  "#a5b4fc",
  "#6366f1",
  "#4338ca",
  "#f0abfc",
  "#d946ef",
  "#a21caf",
  "#f9a8d4",
  "#ec4899",
  "#be185d",
];
export default function CustomColorPicker({
  excludedColors,
  ...props
}: { excludedColors?: string[] } & ColorPickerProps) {
  const colors = presetColors.filter(
    (color) => !excludedColors?.includes(color)
  );
  return (
    <ColorPicker
      className="stage-colorpicker"
      defaultValue={props.defaultValue ?? "#d1d5db"}
      {...props}
      presets={[
        {
          label: "Recommended",
          colors,
        },
      ]}
    />
  );
}
