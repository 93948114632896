import { PlusOutlined } from "@ant-design/icons";
import { DeleteButton, EditButton, List, useTable } from "@refinedev/antd";
import { BaseRecord, useTranslate } from "@refinedev/core";
import { Button, Grid, Space, Table, Tag } from "antd";
import React, { PropsWithChildren, useState } from "react";
import { useUser } from "../../../contexts/ContextProvider";
import CreateOrUpdateGroup from "./create-update-group";

const { useBreakpoint } = Grid;

const GroupList: React.FC<PropsWithChildren> = ({ children }) => {
  const translate = useTranslate();
  const { currentTeam } = useUser();
  const { tableProps } = useTable({
    resource: "tags",
    syncWithLocation: false,
    meta: {
      select: "*, contacts(id)",
    },
    filters: {
      permanent: [
        {
          field: "account",
          value:
            currentTeam?.account_id ?? "00000000-0000-0000-0000-000000000000",
          operator: "eq",
        },
      ],
    },
  });

  const screens = useBreakpoint();

  const [openModal, setOpenModal] = useState<{
    id?: number | string;
    open: boolean;
  }>();

  return (
    <>
      <List
        title={translate("groups.groups")}
        headerButtons={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setOpenModal({ open: true })}
          >
            Neue Gruppe anlegen
          </Button>
        }
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="name" title={"Name"} />
          <Table.Column
            dataIndex="count"
            title={translate("tags.count")}
            render={(_, record: any) => (
              <>
                <Tag>{record.contacts.length}</Tag>
              </>
            )}
          />

          <Table.Column
            title={translate("tags.actions")}
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
              <Space>
                <EditButton
                  hideText
                  size={screens.sm ? "small" : "middle"}
                  recordItemId={record.id}
                  onClick={() => setOpenModal({ open: true, id: record.id })}
                />
                <DeleteButton
                  hideText
                  size={screens.sm ? "small" : "middle"}
                  recordItemId={record.id}
                  resource="tags"
                />
              </Space>
            )}
          />
        </Table>
      </List>
      {openModal?.open && (
        <CreateOrUpdateGroup
          id={openModal?.id}
          open={!!openModal?.open}
          onClose={() => setOpenModal({ open: false })}
        />
      )}
      {children}
    </>
  );
};
export default GroupList;
