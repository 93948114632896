export function getContactFormatedName(
  company_name?: string | undefined,
  firstname?: string | undefined,
  lastname?: string | undefined,
  division_name?: string | undefined,
  email?: string | undefined
) {
  if (!firstname && !lastname && !company_name && !division_name) {
    return email;
  }

  let str = "";

  if (firstname) {
    str += firstname;
  }

  if (lastname) {
    str += (str ? " " : "") + lastname;
  }

  if (company_name) {
    str += (str ? ", " : "") + company_name;
  }

  if (division_name) {
    str += (str ? ", " : "") + division_name;
  }

  return str;
}
