export const numberToCurrency = (
  number: number,
  currency: "eur" | "usd" | "chf" = "eur"
) =>
  Number(Math.round(number * 100) / 100).toLocaleString("de-DE", {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
    useGrouping: true,
    minimumFractionDigits: 2,
  });

export function formatCurrency(number: string) {
  // Convert the number to a string and check if it contains a decimal point
  const numberStr = number.toString();
  let formattedNumber;

  // Check if the number has a decimal point
  if (numberStr.includes(".")) {
    // Split the number into whole and decimal parts
    const parts = numberStr.split(".");
    const wholePart = parts[0];
    const decimalPart = parts[1];

    // If there are two or less decimals, format accordingly
    if (decimalPart.length <= 2) {
      formattedNumber = wholePart + "," + (decimalPart + "0").slice(0, 2);
    } else {
      // If there are more than two decimals, truncate and format
      formattedNumber = wholePart + "," + decimalPart.slice(0, 2);
    }
  } else {
    // If there is no decimal, treat as a whole number and format
    if (numberStr.length === 1) {
      // If single digit, prepend '0.0'
      formattedNumber = "0.0" + numberStr;
    } else if (numberStr.length === 2) {
      // If two digits, prepend '0.'
      formattedNumber = "0." + numberStr;
    } else {
      // If more than two digits, insert comma before last two digits
      formattedNumber = numberStr.slice(0, -2) + "," + numberStr.slice(-2);
    }
  }

  return formattedNumber;
}

export const currencySymbols = {
  USD: "$", // US Dollar
  EUR: "€", // Euro
  GBP: "£", // British Pound
  JPY: "¥", // Japanese Yen
  // Add more currencies and their symbols as needed
};
