const CogIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={"1.5em"}
    fill={props.color ? props.color : "currentColor"}
    viewBox="0 0 512 512"
    {...props}
  >
    <path fill="none" d="M303.1 501h-1" />
    <path d="M501 300.8v-91.7h-45.3c-5.3-22.4-14.3-43.3-26.4-62.1l32.9-32.7-64.9-64.6L363.9 83c-18.8-11.5-39.6-19.9-61.8-24.8V11H210v48.3c-22 5.4-42.6 14.4-61.1 26.4l-34.2-34-64.9 64.6 35.3 35.2c-2.8 4.6-5.3 9.2-7.7 14-7.5 14.3-13.2 30-17.1 45.7H11v91.7h50.3c1.5 6 3.3 11.9 5.3 17.8.1.4.3.8.4 1.2 0 .1.1.2.1.4 4.9 14.2 11.3 27.7 19.1 40.2l-35.5 35.3 64.9 64.6 35.1-34.9c18.3 11.5 38.6 20.2 60.2 25.4V501H302v-47.1c22.7-5 44-13.7 63.1-25.6l32.2 32 64.9-64.6-32.1-31.9c12-19.1 20.9-40.3 26-62.9H501zm-94.8 64 29.9 29.8-36.6 36.5-29.5-29.4c-24.7 18.9-54.4 31.7-86.7 36v42.4H232v-42.7c-31.5-4.6-60.4-17.2-84.6-35.7l-31.6 31.5-36.6-36.5 32.4-31.3c-17.9-24-30-52.4-34.4-83.4H31.9v-51.1h44l1.5-3.6c4.7-29.7 16.5-57.1 33.6-80.3l-32-31.9 36.6-36.5 31 31.9c24-18.5 52.8-31.2 84.1-36V31.8H282v42.3c32 4.1 61.3 16.4 86 34.8l30.3-30.1 35.6 36.5-29.6 29.5c18.2 23.8 30.7 52.2 35.5 83.1h45.4V279h-44.7c-3.9 31.8-16.1 61.1-34.3 85.8z" />

    <path d="M257 143.4c-61.8 0-113.1 50-113.1 112.6S195.3 368.6 257 368.6 370.1 317.5 370.1 256 318.8 143.4 257 143.4zm0 204.3c-51.3 0-92.1-40.7-92.1-91.7 0-51.1 41.9-91.7 92.1-91.7s92.1 40.7 92.1 91.7c.1 51.1-41.8 91.7-92.1 91.7z" />
  </svg>
);
export default CogIcon;
