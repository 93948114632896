import { useSelect } from "@refinedev/antd";
import { useUser } from "../contexts/ContextProvider";
import { BillingType } from "../interfaces/phase.interface";

export const useProductSelect = () => {
  const { currentTeam } = useUser();

  return useSelect<{
    quantity: number;
    billing_interval: BillingType;
    invoice_interval: number;
    sales_tax: number;
    currency: string;
    netto_price: any;
    id: number | string;
    title: string;
  }>({
    resource: "product_items",
    optionLabel: "title",
    optionValue: "id",
    meta: {
      select: "*",
    },
    filters: [
      {
        field: "account",
        operator: "eq",
        value: currentTeam?.account_id,
      },
    ],
  });
};
