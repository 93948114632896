import { Divider, Input, Typography, theme } from "antd";
import { InputProps, InputRef } from "antd/lib/input";
import React from "react";

type Props = {
  label?: string;
} & InputProps;

const CustomInput = React.forwardRef<InputRef, Props>(
  ({ label, ...props }, ref) => {
    const { token } = theme.useToken();

    return (
      <>
        <Input
          {...props}
          ref={ref}
          variant="borderless"
          size="large"
          style={{ fontSize: "30px" }}
        />
        <Divider
          style={{
            borderColor: props.disabled
              ? token.colorTextDisabled
              : token.colorPrimary,
            marginBlock: "5px",
          }}
        />
        {label && (
          <Typography.Text
            disabled={props.disabled}
            style={{
              color: props.disabled
                ? token.colorTextDisabled
                : token.colorPrimary,
            }}
          >
            {label}
          </Typography.Text>
        )}
      </>
    );
  }
);

export default CustomInput;
