import { DownloadOutlined } from "@ant-design/icons";
import { useTable } from "@refinedev/antd";
import { useNavigation, useTranslate } from "@refinedev/core";
import {
  Button,
  Col,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { PropsWithChildren, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { numberToCurrency } from "../../utility";

const InvoicesList: React.FC<PropsWithChildren> = ({ children }) => {
  const [touch, setTouch] = useState(false);
  const translate = useTranslate();
  const { list } = useNavigation();
  const { currentTeam } = useUser();
  const { tableProps } = useTable({
    resource: "invoices",
    meta: {
      select: "*",
      schema: "basejump",
    },
    syncWithLocation: true,
    filters: {
      permanent: [
        {
          field: "account",
          value: currentTeam?.account_id,
          operator: "eq",
        },
      ],
    },
  });

  if (!currentTeam || !currentTeam?.account_id || tableProps.loading) {
    return <Spin />;
  }

  return (
    <>
      <Modal
        open
        title={false}
        onCancel={() => {
          close();
          list("settings", "replace");
        }}
        styles={{
          body: {
            overflowY: "auto",
            maxHeight: "80vh",
            padding: 50,
          },
        }}
        width={"70%"}
        centered
        closeIcon={false}
        footer={
          <>
            <Button
              onClick={() => {
                close();
                list("settings", "replace");
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={!touch}
              onClick={async () => {
                //
              }}
            >
              Save
            </Button>
          </>
        }
      >
        <Row>
          <Col md={8}>
            <Typography.Title level={4}>Invoices</Typography.Title>
            <Typography.Paragraph type="secondary">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras elit
              orci, iaculis id eros ac.
            </Typography.Paragraph>
          </Col>
        </Row>
        <Row gutter={20} style={{ marginTop: 50 }}>
          <Col md={8}>
            <Typography.Title level={5}>Invoices</Typography.Title>
            <Typography.Paragraph type="secondary">
              Information for all invoices generated
            </Typography.Paragraph>
          </Col>
          <Col md={16}>
            <Table
              {...tableProps}
              rowKey={(record) => record.id ?? ""}
              rowClassName={"clickableRow"}
              loading={currentTeam === undefined}
              pagination={{
                ...tableProps.pagination,
                showSizeChanger: true,
              }}
            >
              <Table.Column
                dataIndex="date"
                title={translate("settings.invoices.fields.date", "Date")}
                render={(val) => {
                  return (
                    <>
                      {val
                        ? new Date(val).toLocaleString(
                            translate("pipelines.date_locale")
                          )
                        : null}
                    </>
                  );
                }}
              />
              <Table.Column
                dataIndex="invoice_no"
                title={translate(
                  "settings.invoices.fields.invoice_no",
                  "Invoice No"
                )}
              />
              <Table.Column
                dataIndex="amount"
                render={(text) => <div>{numberToCurrency(text)}</div>}
                title={translate("settings.invoices.fields.amount", "Amount")}
              />
              <Table.Column
                dataIndex="status"
                render={(text) =>
                  text == "open" ? (
                    <Tag color="orange">{text}</Tag>
                  ) : (
                    <Tag color="green">{text}</Tag>
                  )
                }
                title={translate("settings.invoices.fields.amount", "Amount")}
              />
              <Table.Column
                render={() => (
                  <Space size="middle">
                    <Button icon={<DownloadOutlined />} size="small" />
                  </Space>
                )}
              />
            </Table>
          </Col>
        </Row>
      </Modal>
      {children}
    </>
  );
};
export default InvoicesList;
