import {
  CommentOutlined,
  SearchOutlined,
  TagOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useList, useTranslate } from "@refinedev/core";
import { AutoComplete, Input, Modal, Space, Spin } from "antd";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../contexts/ContextProvider";
import { getContactFormatedName } from "../../utility/contactName";
import { useStyles } from "../header/styled";
import { Text } from "../text";

type Props = {
  openDialog?: boolean;
  handleCancel?: () => void;
};

interface IOptionGroup {
  value: string;
  label: string | React.ReactNode;
}
interface IOptions {
  label: string | React.ReactNode;
  options: IOptionGroup[];
}
export default function SearchModal({ openDialog, handleCancel }: Props) {
  const [options, setOptions] = useState<IOptions[]>([]);
  const [value, setValue] = useState<string>("");

  const { currentTeam } = useUser();
  const { styles } = useStyles();
  const translate = useTranslate();
  const renderTitle = (title: string) => (
    <div>
      <Text size="xs" strong type="secondary" style={{ paddingLeft: 6 }}>
        {title}
      </Text>
    </div>
  );

  const renderItem = (title: string | undefined, link: string, id: string) => {
    const regex = new RegExp(
      `(${value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")})`,
      "gi"
    );

    const parts = title != undefined ? title.split(regex) : [];
    return {
      key: id,
      value: `#${id} - ${title}`,
      label: (
        <div className={styles.labelStyle}>
          <Link
            key={id}
            to={link}
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => setValue("")}
          >
            <Space>
              {id.startsWith("contact") ? (
                <UserOutlined />
              ) : id.startsWith("notes") ? (
                <CommentOutlined />
              ) : (
                <TagOutlined />
              )}
              <Text size="sm">
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={
                      part.toLowerCase() === value.toLowerCase()
                        ? { background: "#FFFF8D" }
                        : {}
                    }
                  >
                    {part}
                  </span>
                ))}
              </Text>
            </Space>
          </Link>
        </div>
      ),
    };
  };
  const { refetch: refetchContacts } = useList<any>({
    resource: "contacts",
    filters: [
      {
        operator: "eq",
        value:
          currentTeam?.account_id ?? "00000000-0000-0000-0000-000000000000",
        field: "account",
      },
      {
        operator: "or",
        value: [
          {
            field: "firstname",
            operator: "contains",
            value: `%${value}%`,
          },
          {
            field: "lastname",
            operator: "contains",
            value: `%${value}%`,
          },
          {
            field: "company_name",
            operator: "contains",
            value: `%${value}%`,
          },
          {
            field: "division_name",
            operator: "contains",
            value: `%${value}%`,
          },
        ],
      },
    ],
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const orderOptionGroup = data.data.map((item) =>
          renderItem(
            getContactFormatedName(
              item.company_name,
              item.firstname,
              item.lastname,
              item.division_name
            ),
            `/${currentTeam?.slug}/contacts/show/${item.id}`,
            `contact-${item.id}`
          )
        );

        if (orderOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            ...prevOptions,
            {
              label: renderTitle(translate("contacts.contacts")),
              options: orderOptionGroup,
            },
          ]);
        }
      },
    },
  });

  const { refetch: refetchDeals } = useList<any>({
    resource: "pipeline_items",
    filters: [
      {
        operator: "eq",
        value:
          currentTeam?.account_id ?? "00000000-0000-0000-0000-000000000000",
        field: "account",
      },
      { field: "name", operator: "contains", value: `%${value}%` },
    ],

    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const storeOptionGroup = data.data.map((item) =>
          renderItem(
            `${item.name}`,
            `/${currentTeam?.slug}/pipelines/show/${item.pipeline}/items/${item.id}`,
            `items-${item.id}`
          )
        );
        if (storeOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            ...prevOptions,
            {
              label: renderTitle("Deals"),
              options: storeOptionGroup,
            },
          ]);
        }
      },
    },
  });

  const { refetch: refetchNotes } = useList<any>({
    resource: "notes",
    meta: { select: "*, contacts(*)" },
    filters: [
      {
        operator: "eq",
        value:
          currentTeam?.account_id ?? "00000000-0000-0000-0000-000000000000",
        field: "account",
      },
      { field: "text", operator: "contains", value: `%${value}%` },
    ],
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const courierOptionGroup = data.data.map((item) =>
          renderItem(
            `${item.text}`,
            `/${currentTeam?.slug}/contacts/show/${item.contacts[0]?.id}?noteHighlight=${item.id}`,
            `notes-${item.id}`
          )
        );

        if (courierOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            ...prevOptions,
            {
              label: renderTitle(translate("notes.notes")),
              options: courierOptionGroup,
            },
          ]);
        }
      },
    },
  });

  useEffect(() => {
    if (openDialog) {
      setOptions([]);
      refetchContacts();
      refetchDeals();
      refetchNotes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, openDialog]);

  if (!options) <Spin />;
  return (
    <>
      <Modal
        title={
          <>
            <AutoComplete
              style={{
                width: "100%",
                marginRight: "1rem",
              }}
              open={false}
              options={options}
              filterOption={false}
              onSearch={debounce((value: string) => setValue(value), 1000)}
              className={styles.inputStyle}
            >
              <Input
                style={{
                  height: "50px",
                  fontSize: 16,
                  borderRadius: "10px 10px 0px 0",
                  borderTop: "unset",
                }}
                className={styles.inputStyle}
                placeholder={translate("page.home.search")}
                suffix={<div className={styles.inputSuffix}>/</div>}
                prefix={<SearchOutlined className={styles.inputPrefix} />}
              />
            </AutoComplete>
          </>
        }
        centered
        open={openDialog}
        footer={null}
        closeIcon={false}
        styles={{
          body: {
            maxHeight: 400,
            height: 400,
            overflow: "auto",
            padding: "10px 0px",
            borderTop: "1px solid #eee",
          },
          header: {
            padding: 0,
            paddingBottom: 20,
            border: 0,
          },
        }}
        onCancel={handleCancel}
      >
        {options.map((item, index) => {
          return (
            <Space key={index} direction="vertical" style={{ width: "100%" }}>
              <Space style={{ padding: 5 }}>{item.label}</Space>
              {item.options.map((option, indx) => {
                return (
                  <React.Fragment key={indx}>{option.label}</React.Fragment>
                );
              })}
            </Space>
          );
        })}
      </Modal>
    </>
  );
}
