import { PlusOutlined, WarningOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import { useNavigation, useNotification, useTranslate } from "@refinedev/core";
import { Button, Col, Flex, Modal, Row, Space, Typography } from "antd";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { usePipelineContext } from "../../contexts/PipelineProvider";
import InfoComponent from "../InfoComponent";

const ShopSettings: React.FC<PropsWithChildren> = ({ children }) => {
  const [touch, setTouch] = useState(false);
  const [modal, setModal] = useState(false);
  const [shopModule, setShopModule] = useState(false);
  const { canAccess, setCanAccess } = usePipelineContext();

  const { currentTeam, updateTeam, refetchTeams } = useUser();
  const { open } = useNotification();
  const translate = useTranslate();

  const { list } = useNavigation();

  async function updateModalStatus(moduleParam: string, value: boolean) {
    const obj = { ...currentTeam?.metadata?.module, [moduleParam]: value };
    if (currentTeam?.account_id) {
      await updateTeam(currentTeam?.account_id, {
        public_metadata: {
          ...currentTeam?.metadata,
          module: obj,
        },
      });
    }
    setCanAccess({ ...canAccess, personal_modules: obj });
    refetchTeams();
    open?.({
      description: translate("notifications.success"),
      message: translate("notifications.editSuccess"),
      type: "success",
    });
  }
  const { modalProps, close } = useModalForm({
    action: "edit",
    defaultVisible: true,
  });
  useEffect(() => {
    if (
      currentTeam?.metadata?.module?.shop !== undefined &&
      currentTeam.metadata?.module?.shop !== null
    )
      setShopModule(currentTeam.metadata?.module?.shop);
  }, [currentTeam?.metadata?.module]);

  return (
    <>
      <Modal
        {...modalProps}
        title={false}
        onCancel={() => {
          close();
          list("settings", "replace");
        }}
        styles={{
          body: {
            overflowY: "auto",
            maxHeight: "80vh",
            padding: 50,
          },
        }}
        width={"70%"}
        centered
        closeIcon={false}
        footer={
          <Flex justify="space-between">
            {canAccess?.modules?.shop && shopModule ? (
              <>
                <Button
                  danger
                  type="link"
                  onClick={async () => {
                    setModal(true);
                  }}
                >
                  Disable Shop
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="link"
                  onClick={async () => {
                    setModal(true);
                  }}
                  disabled={canAccess?.modules?.shop === false}
                >
                  Activate Shop
                </Button>
              </>
            )}
            <Space>
              <Button
                onClick={() => {
                  close();
                  list("settings", "replace");
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={!touch}
                onClick={async () => {
                  //
                }}
              >
                Save
              </Button>
            </Space>
          </Flex>
        }
      >
        <Row>
          <Col md={8}>
            <Typography.Title level={4}>Shop Settings</Typography.Title>
            <Typography.Paragraph type="secondary">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras elit
              orci, iaculis id eros ac.
            </Typography.Paragraph>
          </Col>
        </Row>
        {shopModule ? <>Comming Soon</> : <InfoComponent />}
        {children}
      </Modal>
      <Modal
        open={modal}
        onOk={() => {
          setShopModule(!shopModule);
          updateModalStatus("shop", !shopModule);
          setModal(false);
        }}
        title={
          shopModule ? (
            <>
              <WarningOutlined /> Disable Shop
            </>
          ) : (
            <>
              <PlusOutlined /> Enable Shop
            </>
          )
        }
        onCancel={() => setModal(false)}
      >
        {shopModule
          ? "The module will be disabled. Data and settings will not be reset. You can enable the module later again"
          : "The module will be enabled. You have to check the settings in order to make it work for your needs"}
      </Modal>
    </>
  );
};
export default ShopSettings;
