import { Typography } from "antd";

type Props = {
  data: any;
};

export default function InfoAutomation({ data }: Props) {
  const getFrequencyDescription = () => {
    switch (data?.frequency?.type) {
      case "daily":
        return `every day at ${data?.frequency?.time}`;
      case "weekly":
        return `every ${
          data?.frequency?.interval
        } week(s) on ${data?.frequency?.daysOfWeek?.join(", ")}`;
      case "monthly":
        return `every ${data?.frequency?.interval} month(s) on day ${data?.frequency?.day}`;
      case "yearly":
        return `every year on ${data?.frequency?.month} ${data?.frequency?.day}`;
      default:
        return "a custom frequency";
    }
  };

  const getWhenToStartDescription = () => {
    switch (data?.frequency?.whenToStart) {
      case "scheduled":
        return `on a scheduled basis, ${getFrequencyDescription()}`;
      case "certain_date":
        return `on ${new Date(data?.frequency?.certainDate).toLocaleString()}`;
      case "date_field":
        return `based on the date field ${data?.frequency?.dateField}`;
      case "event_triggered":
        return `when the event ${data?.frequency?.event} occurs`;
      default:
        return "an unknown trigger";
    }
  };

  const getFilteringDescription = () => {
    const groups = data?.criterias?.groups?.length
      ? `Groups: ${data?.criterias?.groups.join(", ")}`
      : null;
    const segment = data?.criterias?.segment?.query
      ? `Segment: ${JSON.stringify(data?.criterias?.segment.query)}`
      : null;

    return [groups, segment].filter(Boolean).join(" and ");
  };

  return (
    <Typography.Text
      style={{
        textAlign: "center",
        maxWidth: "80%",
        margin: "0 auto",
        display: "block",
      }}
    >
      This automation is based on{" "}
      <Typography.Text strong>{data.based_on}</Typography.Text> and will run{" "}
      <Typography.Text strong>{getWhenToStartDescription()}</Typography.Text>.{" "}
      {getFilteringDescription() && (
        <>
          It is filtered by{" "}
          <Typography.Text strong>{getFilteringDescription()}</Typography.Text>.
        </>
      )}
    </Typography.Text>
  );
}
