import { useForm } from "@refinedev/antd";
import { BaseKey, useNavigation, useResourceParams } from "@refinedev/core";
import {
  Checkbox,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  TimePicker,
} from "antd";
import dayjs from "dayjs";
import { PropsWithChildren, useEffect, useState } from "react";
import FilterGroup from "../../components/contacts/filter-group";
import { useUser } from "../../contexts/ContextProvider";

interface Props extends PropsWithChildren {
  edit?: any;
  openEdit?: boolean;
  onCloseEdit?: () => void;
}

type FrequencyOption = "daily" | "weekly" | "monthly" | "yearly";

export default function AutomationCreateUpdate({
  children,
  edit,
  openEdit,
  onCloseEdit,
}: Props) {
  const { currentTeam } = useUser();
  const { list } = useNavigation();
  const { id } = useResourceParams();
  const [basedOn, setBasedOn] = useState(null);
  const [whenToStart, setWhenToStart] = useState(null);
  const [groups, setGroups] = useState<BaseKey[]>();
  const [segment, setSegment] = useState();
  const [frequency, setFrequency] = useState<FrequencyOption>();
  // const { RangePicker } = DatePicker;
  const { Option } = Select;

  const onFrequencyChange = (value: FrequencyOption) => {
    setFrequency(value);
  };

  // const onDateRangeChange = (
  //   dates: [Dayjs | null, Dayjs | null] | null,
  //   dateStrings: [string, string]
  // ) => {
  //   console.log(dates, dateStrings);
  // };

  const { formProps, saveButtonProps } = useForm({
    resource: "automations",
    action: edit ? "edit" : "create",
    id,
    redirect: "edit",
  });

  useEffect(() => {
    const initialBasedOn = formProps?.initialValues?.based_on;
    const initialWhenToStart = formProps?.initialValues?.frequency?.whenToStart;
    const initialFrequency = formProps?.initialValues?.frequency?.type;
    const initialGroups = formProps?.initialValues?.criterias?.groups;
    const initialSegment = formProps?.initialValues?.criterias?.segment;

    if (initialBasedOn) {
      setBasedOn(initialBasedOn);
    }
    if (initialWhenToStart) {
      setWhenToStart(initialWhenToStart);
    }
    if (initialFrequency) {
      setFrequency(initialFrequency);
    }
    if (initialGroups) {
      setGroups(initialGroups);
    }
    if (initialSegment) {
      setSegment(initialSegment);
    }
  }, [formProps.initialValues]);

  const FilterGroupComponent = (
    <FilterGroup
      key={JSON.stringify(formProps?.initialValues?.criterias?.segment)}
      handleGroups={(selectedGroup) => setGroups(selectedGroup)}
      handleSegments={(selectedSegments) => {
        setSegment(selectedSegments);
      }}
      groupsValue={groups}
      initialSegmentValue={formProps?.initialValues?.criterias?.segment}
      showInWeek
    />
  );

  return (
    <>
      <Modal
        open={edit ? openEdit : true}
        okButtonProps={{
          ...saveButtonProps,
        }}
        onCancel={() => {
          if (edit) {
            formProps.form?.resetFields();
            onCloseEdit && onCloseEdit();
          } else {
            list("automations");
          }
        }}
        title={`${edit ? "Edit" : "Create"} Automation`}
        centered
        styles={{
          body: {
            overflowY: "auto",
            maxHeight: "70dvh",
          },
        }}
      >
        <Form
          {...formProps}
          onFinish={(values: any) => {
            const vel = {
              name: values?.name,
              based_on: values?.based_on,
              criterias: {
                groups: groups,
                segment: segment,
              },
              frequency: values?.frequency,
              account: currentTeam?.account_id,
            };
            return formProps.onFinish && formProps?.onFinish(vel);
          }}
          layout="vertical"
          style={{ marginBottom: edit ? -25 : "auto" }}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please enter a name",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="based_on"
            label="Based on"
            rules={[
              {
                required: true,
                message: "Please select a base",
              },
            ]}
          >
            <Select
              onChange={(value) => setBasedOn(value)}
              options={[{ label: "Contacts", value: "contacts" }]}
            />
          </Form.Item>

          {basedOn === "contacts" && (
            <>
              <Form.Item
                name="group"
                label="Groups and Segments"
                tooltip={
                  "Use this to filter and group data for the automation. Groups and segments allow you to define specific filtering criteria that determine which records the automation will target."
                }
              >
                {FilterGroupComponent}
              </Form.Item>

              <Form.Item
                name={["frequency", "whenToStart"]}
                label="When to start"
                rules={[
                  {
                    required: true,
                    message: "Please select when to start",
                  },
                ]}
                tooltip={
                  <>
                    Select when the automation should start:
                    <br />- <b>Scheduled</b>: Set a frequency (Daily, Weekly,
                    Monthly, Yearly) to define how often the automation runs.
                    <br />- <b>Certain Date</b>: Run the automation on a
                    specific date.
                    <br />- <b>Based on a Date Field</b>: Trigger the automation
                    based on a specific date field in your data.
                    <br />- <b>Triggered by an Event</b>: The automation will
                    run when a specific event occurs, such as the creation of a
                    new record.
                  </>
                }
              >
                <Select
                  onChange={(value) => setWhenToStart(value)}
                  options={[
                    { label: "Scheduled", value: "scheduled" },
                    { label: "Certain Date", value: "certain_date" },
                    { label: "Based on a Date Field", value: "date_field" },
                    {
                      label: "Triggered by an Event",
                      value: "event_triggered",
                    },
                  ]}
                />
              </Form.Item>

              {whenToStart === "scheduled" && (
                <Form.Item name="frequency">
                  <Form.Item
                    label="Frequency"
                    name={["frequency", "type"]}
                    rules={[
                      {
                        required: true,
                        message: "Please select a frequency",
                      },
                    ]}
                  >
                    <Select value={frequency} onChange={onFrequencyChange}>
                      <Option value="daily">Daily</Option>
                      <Option value="weekly">Weekly</Option>
                      <Option value="monthly">Monthly</Option>
                      <Option value="yearly">Yearly</Option>
                    </Select>
                  </Form.Item>

                  {(frequency === "daily" || frequency === "weekly") && (
                    <Form.Item
                      label="Time"
                      name={["frequency", "time"]}
                      rules={[
                        {
                          required: true,
                          message: "Please select a time",
                        },
                      ]}
                      getValueProps={(value) => ({
                        value: value && dayjs(value),
                      })}
                    >
                      <TimePicker format="HH:mm" />
                    </Form.Item>
                  )}

                  {frequency === "weekly" && (
                    <Form.Item
                      label="Days of the Week"
                      name={["frequency", "daysOfWeek"]}
                      rules={[
                        {
                          required: true,
                          message: "Please select at least one day of the week",
                        },
                      ]}
                    >
                      <Checkbox.Group
                        options={[
                          { label: "Monday", value: "monday" },
                          { label: "Tuesday", value: "tuesday" },
                          { label: "Wednesday", value: "wednesday" },
                          { label: "Thursday", value: "thursday" },
                          { label: "Friday", value: "friday" },
                          { label: "Saturday", value: "saturday" },
                          { label: "Sunday", value: "sunday" },
                        ]}
                      />
                    </Form.Item>
                  )}
                  <Flex>
                    {(frequency === "monthly" || frequency === "yearly") && (
                      <Form.Item
                        label="Day of the Month"
                        name={["frequency", "day"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select a day of the month",
                          },
                          {
                            type: "number",
                            min: 1,
                            max: 31,
                            message: "Please select a valid day (1-31)",
                          },
                        ]}
                      >
                        <InputNumber defaultValue={15} min={1} max={31} />
                      </Form.Item>
                    )}

                    {(frequency === "weekly" || frequency === "monthly") && (
                      <Form.Item
                        label="Every"
                        name={["frequency", "interval"]}
                        extra={frequency === "weekly" ? "week(s)" : "month(s)"}
                        rules={[
                          {
                            required: true,
                            message: `Please enter a number of ${
                              frequency === "weekly" ? "weeks" : "months"
                            }`,
                          },
                        ]}
                        initialValue={1}
                      >
                        <InputNumber min={1} />
                      </Form.Item>
                    )}

                    {frequency === "yearly" && (
                      <Form.Item
                        label="Month"
                        style={{ flex: 0.5 }}
                        name={["frequency", "month"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select the month",
                          },
                        ]}
                      >
                        <Select
                          options={[
                            { label: "January", value: "january" },
                            { label: "February", value: "february" },
                            { label: "March", value: "march" },
                            { label: "April", value: "april" },
                            { label: "May", value: "may" },
                            { label: "June", value: "june" },
                            { label: "July", value: "july" },
                            { label: "August", value: "august" },
                            { label: "September", value: "september" },
                            { label: "October", value: "october" },
                            { label: "November", value: "november" },
                            { label: "December", value: "december" },
                          ]}
                        />
                      </Form.Item>
                    )}
                  </Flex>
                </Form.Item>
              )}

              {whenToStart === "certain_date" && (
                <Form.Item
                  name={["frequency", "certainDate"]}
                  label="Select Date and Time"
                  getValueProps={(value) => ({
                    value: value && dayjs(Number(value)),
                  })}
                  normalize={(value) => value && `${dayjs(value).valueOf()}`}
                >
                  <DatePicker showTime />
                </Form.Item>
              )}

              {whenToStart === "date_field" && (
                <Form.Item
                  name={["frequency", "dateField"]}
                  label="Select a Date Field"
                  rules={[
                    {
                      required: true,
                      message: "Please select a date field",
                    },
                  ]}
                >
                  <Select
                    options={[
                      { label: "Created At", value: "created_at" },
                      { label: "Updated At", value: "updated_at" },
                      // Add other date fields here
                    ]}
                  />
                </Form.Item>
              )}

              {whenToStart === "event_triggered" && (
                <Form.Item
                  name={["frequency", "event"]}
                  label="Select Event"
                  rules={[
                    {
                      required: true,
                      message: "Please select an event",
                    },
                  ]}
                >
                  <Select
                    options={[
                      { label: "Create", value: "INSERT" },
                      { label: "Update", value: "UPDATE" },
                      { label: "Delete", value: "DELETE" },
                    ]}
                  />
                </Form.Item>
              )}
            </>
          )}
        </Form>
      </Modal>
      {children}
    </>
  );
}
