import { useForm } from "@refinedev/antd";
import { useNavigation, useTranslate } from "@refinedev/core";
import { Form, Input, Modal } from "antd";
import React, { PropsWithChildren } from "react";
import { useUser } from "../../../contexts/ContextProvider";

const SubscriptionProductsCreate: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const translate = useTranslate();
  const { currentTeam } = useUser();
  const { list } = useNavigation();
  const { formProps, saveButtonProps, onFinish } = useForm({
    resource: "subscription_products",
    action: "create",
    redirect: "list",
  });

  return (
    <>
      <Modal
        open
        maskClosable={false}
        title={translate("subscription_products.create.title")}
        onCancel={() => {
          list("subscription_products", "replace");
        }}
        okText={translate("buttons.save")}
        okButtonProps={{
          ...saveButtonProps,
        }}
        width={760}
      >
        <Form
          {...formProps}
          layout="vertical"
          onFinish={(values) => {
            onFinish({
              ...values,
              account: currentTeam?.account_id,
            });
          }}
        >
          <Form.Item
            label={translate("subscription_products.fields.title")}
            name="title"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={translate("subscription_products.fields.description")}
            name="description"
          >
            <Input.TextArea rows={6} />
          </Form.Item>
        </Form>
      </Modal>
      {children}
    </>
  );
};

export default SubscriptionProductsCreate;
