import dayjs from "dayjs";
import { ActivityResource } from "../interfaces/activity.interface";

export function transformUrl(url: string): string {
  url = url.trim();

  url = url.replace(/^https?:?\/\//i, "");
  url = url.replace(/^www\.?/i, "");

  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    url = "https://" + url;
  }

  return url;
}

export function capitalizeEveryWord(str: string) {
  return str
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}

export const singularizeResource = (resource: ActivityResource) => {
  switch (resource) {
    default:
      return resource.slice(0, -1);
  }
};

export function formatDate(createdAt: string | Date) {
  const date = dayjs(createdAt).startOf("day");
  const today = dayjs().startOf("day");
  const differenceInDays = today.diff(date, "day");
  console.log(differenceInDays);
  if (differenceInDays === 0) {
    return "Today";
  } else if (differenceInDays === 1) {
    return "1 day ago";
  } else if (differenceInDays <= 7) {
    return `${differenceInDays} days ago`;
  } else {
    return date.format("MM/DD/YYYY"); // Or use your preferred date format
  }
}
