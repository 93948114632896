import { useModalForm } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Form, Input, Modal } from "antd";
import { useUser } from "../../../contexts/ContextProvider";

import { useEffect, useState } from "react";
import { formatQuery, RuleGroupType } from "react-querybuilder";
import { parseJsonLogic } from "react-querybuilder/dist/parseJsonLogic";
import CustomQueryBuilder from "./CustomQueryBuilder";
import React from "react";

type Props = {
  id?: string | number;
  open: boolean;
  onClose: () => void;
};

export default function CreateOrUpdate({ open, onClose, id }: Props) {
  const [formQuery, setFormQuery] = useState<RuleGroupType>();
  const [query, setQuery] = useState<RuleGroupType>({
    combinator: "and",
    rules: [],
  });
  const [queryBuilderComponent, setQueryBuilderComponent] =
    useState<React.ReactNode>(null);

  const { modalProps, formProps, form } = useModalForm({
    resource: "segments",
    action: id ? "edit" : "create",
    redirect: false,
    id: id,
    onMutationSuccess: () => {
      onClose();
    },
  });
  const translate = useTranslate();
  const { currentTeam } = useUser();

  useEffect(() => {
    if (id && formProps?.initialValues?.query) {
      const parsed = parseJsonLogic(formProps.initialValues.query);
      setFormQuery(parsed);
      setQuery(parsed);
    }
  }, [formProps?.initialValues?.query, id]);

  useEffect(() => {
    if (formQuery) {
      const newQueryBuilder = React.createElement(CustomQueryBuilder, {
        handleChange: (value) => setQuery(value),
        initialQuery: formQuery,
      });
      setQueryBuilderComponent(newQueryBuilder);
    }
  }, [formQuery]);

  const handleSubmit = async () => {
    try {
      const values: any = await form.validateFields();
      values.query = formatQuery(query, "jsonlogic");
      values.account = currentTeam?.account_id;
      formProps.onFinish?.(values);
    } catch (error) {
      // const values = form.getFieldsValue();
      //   save?.(values, !!error);
    }
  };

  return (
    <Modal
      open={open}
      style={{ maxWidth: 600, zIndex: 1000000 }}
      onCancel={(e) => {
        modalProps.onCancel?.(e);
        onClose();
      }}
      destroyOnClose
      title={translate(`segments.${id ? "edit" : "create"}_segment`)}
      onOk={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) =>
          formProps.onFinish &&
          formProps?.onFinish({
            ...values,
            account: currentTeam?.account_id,
          })
        }
      >
        <Form.Item name={"name"} label={translate("tags.title")}>
          <Input />
        </Form.Item>
        {id && formQuery && queryBuilderComponent}
        {!id && (
          <CustomQueryBuilder handleChange={(value) => setQuery(value)} />
        )}
      </Form>
    </Modal>
  );
}
