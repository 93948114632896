import { useModalForm } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Form, Input, Modal } from "antd";
import { useUser } from "../../../contexts/ContextProvider";

type Props = {
  id?: string | number;
  open: boolean;
  onClose: () => void;
};

export default function CreateOrUpdate({ open, onClose, id }: Props) {
  const { modalProps, formProps } = useModalForm({
    resource: "tags",
    action: id ? "edit" : "create",
    redirect: false,
    id: id,
    onMutationSuccess: () => {
      onClose();
    },
  });
  const translate = useTranslate();
  const { currentTeam } = useUser();
  return (
    <Modal
      {...modalProps}
      open={open}
      style={{ maxWidth: 600, zIndex: 1000000 }}
      onCancel={(e) => {
        modalProps.onCancel?.(e);
        onClose();
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values) =>
          formProps.onFinish &&
          formProps?.onFinish({
            ...values,
            account: currentTeam?.account_id,
          })
        }
      >
        <Form.Item name={"name"} label={translate("tags.title")}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
