import { UploadOutlined } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import { useNavigation, useNotification, useTranslate } from "@refinedev/core";
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import { TableRowSelection } from "antd/es/table/interface";
import { UploadFile } from "antd/lib";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useUser } from "../../../contexts/ContextProvider";
import useFile from "../../../hooks/useFile";
import { supabaseClient } from "../../../utility";
import { beforeUpload } from "../../../utility/generalFunctions";
import CustomColorPicker from "../../form/CustomColorPicker";

const TeamSettings: React.FC<PropsWithChildren> = ({ children }) => {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[] | undefined>();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [touch, setTouch] = useState(false);

  const { open } = useNotification();
  const translate = useTranslate();
  const { list } = useNavigation();

  const { getFileDataURL, removeFile } = useFile();
  const {
    currentTeam,
    updateTeam,
    refetchTeams,
    allTeams,
    teamMembers,
    fetchTeamMembers,
  } = useUser();

  const { form, formProps, modalProps, close } = useModalForm({
    resource: "",
    action: "edit",
    defaultVisible: true,
  });

  const usedTeamsColors = useMemo(
    () =>
      allTeams
        ?.map((team) => team.metadata?.avatar?.color)
        .filter(
          (color) =>
            color !== undefined &&
            color !== currentTeam?.metadata?.avatar?.color
        ),
    [allTeams, currentTeam?.metadata?.avatar?.color]
  );
  useEffect(() => {
    const getAvatar = async () => {
      const images = currentTeam?.metadata?.avatar?.image
        ? [currentTeam.metadata.avatar.image]
        : [];
      if (images) {
        const responses = await Promise.allSettled(
          images.map(async (image) => {
            try {
              const fetchedImg = await getFileDataURL(image, true);

              if (!fetchedImg) {
                throw new Error("Fehler beim Lesen der Datei");
              }
              if (fetchedImg && typeof fetchedImg === "object") {
                return {
                  uid: image?.id ?? "",
                  lastModified: fetchedImg.file.lastModified,
                  lastModifiedDate: fetchedImg.file.lastModified,
                  name: fetchedImg.file.name,
                  size: fetchedImg.file.size,
                  type: fetchedImg.blob.type,
                  percent: 100,
                  originFileObj: {
                    uid: image?.id ?? "",
                  },
                  status: "done",
                  response: "ok",
                  thumbUrl: fetchedImg.base64,
                };
              }
            } catch (error: any) {
              console.error(
                `Error fetching image with id ${image?.id}: ${error.message}`
              );
              return null;
            }
          })
        );

        const successfulResponses = responses
          .filter(
            (result): result is PromiseFulfilledResult<any> =>
              result.status === "fulfilled" && result.value !== null
          )
          .map((result) => result.value);
        setFileList(successfulResponses);
      }
    };
    if (currentTeam?.metadata?.avatar?.image) {
      setTimeout(() => {
        getAvatar();
      }, 500);
    }
  }, [currentTeam?.metadata?.avatar?.image, getFileDataURL]);
  if (!currentTeam) {
    // Optional: Ladezustand oder Platzhalter anzeigen, wenn die Daten noch geladen werden
    return <Spin />;
  }
  const handleChange = async ({ fileList }) => {
    if (fileList.length) {
      setFileList(fileList.filter((file) => file.status !== "error"));
    }
  };
  const uploadAvatar = async (options: any) => {
    const { onSuccess, file } = options;
    try {
      if (!currentTeam.account_id) {
        return;
      }
      const { data: fileData, error } = await supabaseClient.storage
        .from("files")
        .upload(`teams/${currentTeam?.account_id}/avatar`, file, {
          upsert: true,
          cacheControl: "no-cache",
        });
      if (error) {
        console.error("Error uploading file: ", error);
        throw Error(error.message, { cause: error.cause });
      }
      await updateTeam(currentTeam.account_id, {
        public_metadata: {
          ...currentTeam.metadata,
          avatar: {
            ...currentTeam.metadata?.avatar,
            image: { ...fileData, name: "avatar" },
          },
        },
      });
      refetchTeams();
    } catch (error) {
      //
    }
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const onRemove = async () => {
    setDeleteConfirmOpen(false);

    setFileList([]);
    if (currentTeam?.metadata?.avatar?.image)
      currentTeam.metadata.avatar.image = null;
    if (currentTeam.account_id) {
      await updateTeam(currentTeam.account_id, {
        public_metadata: currentTeam.metadata,
      });
      await removeFile([`teams/${currentTeam.account_id}/avatar`]);
    }
    localStorage.removeItem("avatarSrc");
    refetchTeams();
  };
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = file.thumbUrl;
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const uploadPropsText: UploadProps = {
    onChange: handleChange,
    multiple: false,
    customRequest: uploadAvatar,
    onRemove: () => {
      setDeleteConfirmOpen(true);
    },
    beforeUpload: beforeUpload,
    showUploadList: false,
    accept: "image/png, image/jpeg, image/jpg, image/webp",
    maxCount: 1,
    onPreview: handlePreview,
  };
  const uploadProps: UploadProps = {
    onChange: handleChange,
    multiple: false,
    customRequest: uploadAvatar,
    onRemove: () => {
      setDeleteConfirmOpen(true);
    },
    beforeUpload: beforeUpload,
    listType: "picture-circle",
    accept: "image/png, image/jpeg, image/jpg, image/webp",
    maxCount: 1,
    onPreview: handlePreview,
    className: "custom-upload",
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection: TableRowSelection<any> = {
    type: "radio",
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.account_role === "owner" && record.is_primary_owner,
    }),
  };
  const removeMemberFromTeam = async () => {
    const data = await supabaseClient.rpc("remove_account_member", {
      user_id: selectedRowKeys[0],
      account_id: currentTeam?.account_id,
    });
    if (data.status != 204) {
      open?.({
        description: translate("alerts.submitError.title"),
        message: translate("alerts.submitError.defaultMessage"),
        type: "error",
      });
    } else {
      open?.({
        description: translate("notifications.success"),
        message: translate("notifications.editSuccess"),
        type: "success",
      });
      fetchTeamMembers();
    }
  };
  return (
    <>
      <Modal
        {...modalProps}
        title={false}
        onCancel={() => {
          close();
          list("settings", "replace");
        }}
        styles={{
          body: {
            overflowY: "auto",
            maxHeight: "80vh",
            padding: 50,
          },
        }}
        width={"70%"}
        centered
        closeIcon={false}
        footer={
          <>
            <Button
              onClick={() => {
                close();
                list("settings", "replace");
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              disabled={!touch}
              onClick={async () => {
                if (!currentTeam?.account_id) return;
                const test: any = await updateTeam(currentTeam?.account_id, {
                  name: form.getFieldValue("team_name"),
                });
                if (test.name === form.getFieldValue("team_name")) {
                  refetchTeams();
                  open?.({
                    description: translate("notifications.success"),
                    message: translate("notifications.editSuccess"),
                    type: "success",
                  });
                  setTouch(false);
                  formProps?.form?.resetFields();
                  formProps.onFinish?.({});
                }
              }}
            >
              Save
            </Button>
          </>
        }
      >
        <Row>
          <Col md={8}>
            <Typography.Title level={4}>Team Settings</Typography.Title>
            <Typography.Paragraph type="secondary">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras elit
              orci, iaculis id eros ac.
            </Typography.Paragraph>
          </Col>
        </Row>
        <Form
          {...formProps}
          onFieldsChange={() => setTouch(true)}
          form={form}
          layout="vertical"
          style={{ marginTop: 50 }}
        >
          <Row gutter={20}>
            <Col md={8}>
              <Typography.Title level={5}>Team Name</Typography.Title>
              <Typography.Paragraph type="secondary">
                Change the name of the current team
              </Typography.Paragraph>
            </Col>
            <Col md={10}>
              <Form.Item
                name={"team_name"}
                label="Name"
                initialValue={currentTeam?.name}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item label="Logo">
                <Row>
                  <Col md={24} style={{ minHeight: 75 }}>
                    {!fileList?.length && (
                      <Space>
                        <Typography.Text>
                          {translate("teams.avatar_color")}:
                        </Typography.Text>
                        <CustomColorPicker
                          excludedColors={usedTeamsColors}
                          className="stage-colorpicker"
                          onChange={async (color) => {
                            if (!currentTeam?.account_id) {
                              return;
                            }
                            await updateTeam(currentTeam?.account_id, {
                              public_metadata: {
                                ...currentTeam?.metadata,
                                avatar: {
                                  ...currentTeam?.metadata?.avatar,
                                  color: color.toHexString(),
                                },
                              },
                            }).then(() => {
                              refetchTeams();
                            });
                          }}
                          defaultValue={currentTeam?.metadata?.avatar?.color}
                        />
                      </Space>
                    )}
                    <Popconfirm
                      title={translate(
                        "teams.delete_avatar_title",
                        "Delete Avatar"
                      )}
                      description={translate(
                        "buttons.confirm",
                        "Are you sure?"
                      )}
                      open={deleteConfirmOpen}
                      okText="Yes"
                      cancelText="No"
                      onConfirm={onRemove}
                      placement="bottom"
                      onCancel={() => setDeleteConfirmOpen(false)}
                    >
                      <div>
                        <Upload
                          {...uploadPropsText}
                          fileList={
                            fileList && fileList.length > 0
                              ? fileList
                              : undefined
                          }
                        >
                          {fileList?.length != 1 && (
                            <Button
                              style={{ padding: 0 }}
                              type="text"
                              icon={<UploadOutlined />}
                            >
                              Click to Upload
                            </Button>
                          )}
                        </Upload>
                      </div>
                      <div>
                        <Upload
                          {...uploadProps}
                          fileList={fileList}
                          style={{ height: 50 }}
                        />
                      </div>
                    </Popconfirm>
                  </Col>

                  <div>
                    {previewImage && (
                      <Image
                        wrapperStyle={{ display: "none" }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </div>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row gutter={20}>
          <Col md={8}>
            <Typography.Title level={5}>Members</Typography.Title>
            <Typography.Paragraph type="secondary">
              This is the list of current memebers in this team
            </Typography.Paragraph>
          </Col>
          <Col md={16}>
            <Table
              dataSource={teamMembers}
              rowClassName={"clickableRow"}
              rowSelection={rowSelection}
              pagination={false}
              columns={[
                {
                  title: translate("teams.members.name"),
                  dataIndex: "name",
                  key: "name",
                },
                {
                  title: translate("teams.members.account_role"),
                  dataIndex: "account_role",
                  key: "account_role",
                },
                {
                  title: translate("teams.members.email"),
                  dataIndex: "email",
                  key: "email",
                },
              ]}
              rowKey={(record) => record.user_id}
            />
            <Space style={{ marginTop: 20 }}>
              <Button>Add a member</Button>
              {selectedRowKeys.length == 1 && (
                <Popconfirm
                  title={translate("teams.members.title")}
                  description={translate("teams.members.description")}
                  placement="leftBottom"
                  onConfirm={() => removeMemberFromTeam()}
                  okText={translate("buttons.yes")}
                  cancelText={translate("buttons.no")}
                >
                  <Button danger>{translate("teams.members.delete")}</Button>
                </Popconfirm>
              )}
            </Space>
          </Col>
        </Row>
      </Modal>
      {children}
    </>
  );
};
export default TeamSettings;
