import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import { PipelineProvider } from "./contexts/PipelineProvider/usePipelineContext";
import { Module } from "./components/Layout/module";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <PipelineProvider>
      <Module />
      <App />
    </PipelineProvider>
  </React.StrictMode>
);
