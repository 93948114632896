import { createStyles } from "antd-style";

export const useStyles = createStyles(({ token }) => ({
  headerTitle: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "14px",
    fontWeight: "bold",
    borderBottom: "1px",
  },
  inputSuffix: {
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: token.colorBgTextHover,
    color: token.colorTextDisabled,
    borderRadius: "4px",
    fontSize: "12px",
  },
  inputPrefix: {
    color: token.colorTextPlaceholder,
    marginRight: "4px",
  },
  labelStyle: {
    padding: "6px 10px",
    ":hover": {
      borderLeft: "4px solid #1890FF",
      background: "rgba(245, 247, 249, 1)",
    },
  },
  inputStyle: {
    border: "unset",
    borderColor: "unset",
    boxShadow: "none",
    outline: "none",
    outlineColor: "transparent",
    ":focus": {
      outline: "none",
      outlineColor: "transparent",
      border: "unset",
      borderColor: "unset",
      boxShadow: "none",
    },
  },
}));
