import { useForm } from "@refinedev/antd";
import {
  useCreate,
  useCreateMany,
  useGetIdentity,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import { Col, Form, Input, Modal, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";

type Props = {
  onSuccess?: (data: any) => void;
  onClose?: () => void;
  open?: boolean;
};
const CreateContactModal = ({ onSuccess, open, onClose }: Props) => {
  const [requiredField, setRequiredField] = useState<boolean>(true);
  const [valueChanged, setValueChanged] = useState<boolean>(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  const { mutateAsync } = useCreateMany();
  const { mutateAsync: createMutate } = useCreate();
  const { data: me } = useGetIdentity<any>();
  const translate = useTranslate();
  const { currentTeam } = useUser();
  const [salutationPlaceholder, setSalutationPlaceholder] =
    useState<string>("");
  const { list } = useNavigation();
  const { form, saveButtonProps, redirect } = useForm();
  const itemsPhone = [
    {
      label: null,
      number: null,
    },
  ];

  useEffect(() => {
    if (
      (form.getFieldValue("lastname") != null &&
        form.getFieldValue("lastname") != "") ||
      (form.getFieldValue("company_name") != null &&
        form.getFieldValue("company_name") != "")
    ) {
      setRequiredField(false);
    } else {
      setRequiredField(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueChanged]);
  return (
    <Modal
      open={open == undefined ? true : open}
      maskClosable={false}
      title={translate("contacts.create_contact")}
      onCancel={() => {
        onClose ? onClose() : list("contacts", "replace");
      }}
      okText={translate("buttons.save")}
      okButtonProps={{
        ...saveButtonProps,
        loading: saveButtonLoading,
      }}
      width={760}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={async (values: any) => {
          try {
            setSaveButtonLoading(true);
            const contactEntity = await createMutate({
              resource: "contacts",
              meta: {
                select: "*",
              },
              values: {
                email: values.email,
                job_title: values.job_title,
                type: values.type,
                name_prefix: values.name_prefix,
                salutation: values.salutation,
                override_salutation: values.override_salutation,
                url: values.url,
                company_name: values.company_name,
                division_name: values.division_name,
                firstname: values.firstname,
                lastname: values.lastname,
                user: me.id,
                account: currentTeam?.account_id,
              },
            });

            const phToCreate = (values.phone_numbers || [])
              .filter(
                (contact: any) =>
                  contact.number !== undefined && contact.number !== ""
              )
              .map((contact: any, index: number) => ({
                ...contact,
                order: index + 1,
                contact: contactEntity.data.id,
                account: currentTeam?.account_id,
              }));
            await mutateAsync({
              resource: "phone_numbers",
              meta: {
                select: "*",
              },
              values: phToCreate,
              successNotification: false,
            });
            setSaveButtonLoading(false);
            if (onSuccess) {
              onSuccess(contactEntity.data);
            } else {
              redirect("show", contactEntity.data.id);
            }
          } catch (error) {
            setSaveButtonLoading(false);
            Promise.reject(error);
          }
        }}
      >
        <Row gutter={12}>
          <Col span={4}>
            <Form.Item
              label={translate("contacts.title")}
              name={["name_prefix"]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label={translate("contacts.firstname")}
              name={["firstname"]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label={translate("contacts.lastname")}
              name={["lastname"]}
              rules={[
                {
                  required: requiredField,
                  message: "Please input your last name or company name",
                },
              ]}
            >
              <Input onChange={() => setValueChanged(!valueChanged)} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={translate("contacts.salutation")}
              name={["salutation"]}
            >
              <Select
                onChange={(e) => {
                  setSalutationPlaceholder(
                    e === "not_specified"
                      ? "Hallo"
                      : "Sehr geehrte" +
                          (e === "mr" ? "r Herr" : e === "mrs" ? " Frau" : "")
                  );
                }}
                placeholder={translate("contacts.select_salutation")}
                optionFilterProp="children"
                options={[
                  {
                    value: "not_specified",
                    label: translate("contacts.salutation_keine_angabe"),
                  },
                  {
                    value: "mr",
                    label: translate("contacts.salutation_male"),
                  },
                  {
                    value: "mrs",
                    label: translate("contacts.salutation_female"),
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={translate("contacts.override_salutation")}
              name={["override_salutation"]}
            >
              <Input placeholder={salutationPlaceholder} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={translate("contacts.job_title")}
              name={["job_title"]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={translate("contacts.company")}
          name={["company_name"]}
          rules={[
            {
              required: requiredField,
              message: "Please input your company name or lastname",
            },
          ]}
        >
          <Input onChange={() => setValueChanged(!valueChanged)} />
        </Form.Item>
        <Form.Item
          label={translate("contacts.division")}
          name={["division_name"]}
        >
          <Input />
        </Form.Item>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={translate("contacts.email")}
              name={["email"]}
              rules={[
                {
                  required: false,
                  type: "email",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={translate("contacts.url")} name={["url"]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label={translate("contacts.phone")}>
          <Form.List name="phone_number" initialValue={itemsPhone}>
            {(fieldsPhone) => (
              <>
                {fieldsPhone.map((key, name) => {
                  return (
                    <Row gutter={6} key={name}>
                      <Col span={12}>
                        <Form.Item {...key} name={[name, "label"]}>
                          <Input placeholder={translate("contacts.label")} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item {...key} name={[name, "number"]}>
                          <Input placeholder={translate("contacts.number")} />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}
          </Form.List>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateContactModal;
