import { supabaseClient } from "../utility";

export const useDeleteForJunctionTable = async (resource: string, ids: any) => {
  try {
    const { error } = await supabaseClient.from(resource).delete().match(ids);

    if (error) {
      throw error;
    }
    return true;
  } catch (error) {
    return error;
  }
};
export const useDeleteForJunctionTableMany = async (resource, idsArray) => {
  try {
    const orConditions = idsArray
      .map((idObj) => {
        const conditions = Object.entries(idObj)
          ?.map(([key, value]) => `${key}.eq.${value}`)
          ?.join(",");
        return `and(${conditions})`;
      })
      .join(",");

    const { error } = await supabaseClient
      .from(resource)
      .delete()
      .or(orConditions);

    if (error) {
      throw error;
    }
    return true;
  } catch (error) {
    return error;
  }
};
