import { Spin } from "antd";
import React, { PropsWithChildren } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../contexts/ContextProvider";

export const ValidTeamChecker: React.FC<PropsWithChildren> = ({ children }) => {
  const { teamSlug } = useParams();
  const { allTeams } = useUser();
  const validSlugs = allTeams?.map((team: any) => team.slug);

  if (validSlugs === undefined) {
    return <Spin />;
  }

  // Überprüfen, ob der Slug im Array gültiger Slugs vorhanden ist
  if (!validSlugs?.includes(teamSlug ?? "")) {
    // Wenn nicht gültig, Weiterleitung zur 404-Seite
    return <div>404</div>;
  }

  // Wenn gültig, render die Kinderkomponenten
  if (!teamSlug) {
    return <Spin />;
  }
  return <>{children}</>;
};
