import React, { useEffect, useState } from "react";

import { useThemedLayoutContext } from "@refinedev/antd";
import {
  CanAccess,
  ITreeMenu,
  pickNotDeprecated,
  useLink,
  useLogout,
  useMenu,
  useNavigation,
  useTranslate,
} from "@refinedev/core";

import {
  ApartmentOutlined,
  BarChartOutlined,
  DownOutlined,
  LayoutOutlined,
  LogoutOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
  StarOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Drawer,
  Dropdown,
  Flex,
  Grid,
  Layout,
  Menu,
  Space,
  Typography,
  theme,
} from "antd";
import useKey from "use-key-hook";
import { useUser } from "../../contexts/ContextProvider";
import { usePipelineContext } from "../../contexts/PipelineProvider";
import useFile from "../../hooks/useFile";
import useModules from "../../hooks/useModules";
import { getContrastColor } from "../../utility";
import SearchModal from "./search";
import "./sider.css";

const { SubMenu } = Menu;
const { useToken } = theme;

export const Sider: React.FC = () => {
  const [searchDialog, setSearchDialog] = useState<boolean>(false);

  const { token } = useToken();
  const { fetchModuleData, getSlugFromUrl } = useModules();
  const { canAccess } = usePipelineContext();
  const { mutate: logout } = useLogout();
  const translate = useTranslate();
  const { list, replace } = useNavigation();
  const {
    siderCollapsed,
    setSiderCollapsed,
    mobileSiderOpen,
    setMobileSiderOpen,
  } = useThemedLayoutContext();

  const Link = useLink();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const breakpoint = Grid.useBreakpoint();
  const { currentTeam, teamMenuItems, refetchTeams } = useUser();
  const [avatarsrc, setAvatar] = React.useState<string>();
  const { getFileDataURL } = useFile();

  useEffect(() => {
    const getAvatar = async () => {
      const res: any = await new Promise((resolve, reject) => {
        if (!currentTeam?.metadata?.avatar?.image) {
          reject();
        }
        const fetchedImg = getFileDataURL(currentTeam?.metadata?.avatar?.image);

        resolve(fetchedImg);
      });
      setAvatar(res);
      localStorage.setItem("avatarSrc", res);
    };
    if (currentTeam?.metadata?.avatar?.image) {
      getAvatar();
    }
    if (currentTeam?.metadata && !currentTeam?.metadata?.avatar?.image) {
      setAvatar(undefined);
      localStorage.removeItem("avatarSrc");
    }
  }, [currentTeam, getFileDataURL]);
  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

  const renderTreeView = (tree: ITreeMenu[], selectedKey?: string) => {
    return tree.map((item: ITreeMenu) => {
      const {
        icon,
        label,
        route,
        key,
        name,
        children,
        parentName,
        meta,
        options,
      } = item;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { meta: meta2, ...i } = item;

      if (children.length > 0) {
        return (
          <CanAccess
            key={i.key}
            resource={name.toLowerCase()}
            action="list"
            params={{
              resource: i,
            }}
          >
            <SubMenu
              key={item.key}
              icon={icon ?? <UnorderedListOutlined />}
              className={"showOnHoverParent"}
              title={
                <>
                  <Flex justify={"space-between"}>
                    <span>{label}</span>
                    {meta?.secondaryButton}
                  </Flex>
                  {meta?.modal ? (
                    <div onClick={(e) => e.stopPropagation()}>
                      {meta?.modal}
                    </div>
                  ) : null}
                </>
              }
            >
              {renderTreeView(children, selectedKey)}
            </SubMenu>
          </CanAccess>
        );
      }
      const isSelected = key === selectedKey;
      const isRoute = !(
        pickNotDeprecated(meta?.parent, options?.parent, parentName) !==
          undefined && children.length === 0
      );

      return (
        <CanAccess
          key={item.key}
          resource={name.toLowerCase()}
          action="list"
          params={{
            resource: item,
          }}
        >
          <Menu.Item
            key={item.key}
            icon={icon ?? (isRoute && <UnorderedListOutlined />)}
          >
            <Link to={route ?? ""}>{label}</Link>
            {!siderCollapsed && isSelected && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        </CanAccess>
      );
    });
  };
  const items = renderTreeView(menuItems, selectedKey);

  const renderSider = () => {
    return <>{items}</>;
  };

  const renderMenu = () => {
    return (
      <Menu
        selectedKeys={selectedKey ? [selectedKey] : []}
        defaultOpenKeys={[...defaultOpenKeys]}
        mode="inline"
        style={{
          paddingTop: "8px",
          border: "none",
          overflow: "auto",
          height: "calc(100% - 72px)",
          background: "transparent",
          fontWeight: "normal",
        }}
        onClick={() => {
          setMobileSiderOpen(false);
        }}
      >
        <Menu.Item
          key={"search"}
          icon={<SearchOutlined />}
          onClick={() => setSearchDialog(true)}
        >
          {translate("search.search", "Search")}
        </Menu.Item>
        {canAccess?.modules?.sales_kpi && (
          <Menu.Item key={"sales-kpi"} icon={<BarChartOutlined />}>
            <Link to={`/${currentTeam?.slug}/sales-kpi`}>
              {translate("salesKpi.title", "Sales KPI's")}
            </Link>
          </Menu.Item>
        )}
        {renderSider()}
      </Menu>
    );
  };

  const navigateToAccount = () => {
    list("account", "push");
  };
  const itemsTeam = teamMenuItems
    // ?.sort((a) => (a?.key === currentTeam.account_id ? -1 : 0))
    ?.flatMap((item: any) => {
      const currentSelected = item.key == currentTeam?.account_id;
      return [
        {
          ...item,
          disabled: currentSelected,
          label: (
            <a
              rel="noopener noreferrer"
              onClick={() => {
                setMobileSiderOpen(false);
                refetchTeams;
                fetchModuleData(item.slug);
              }}
            >
              <Space>
                <ApartmentOutlined /> {item.label}
              </Space>
            </a>
          ),
        },
      ];
    });

  itemsTeam?.push({
    key: "create_new_teams",

    label: (
      <a
        rel="noopener noreferrer"
        onClick={() => {
          replace("/team/create");
          setMobileSiderOpen(false);
        }}
      >
        <Space>
          <PlusOutlined />{" "}
          {translate("teams.create_new_team", "Create New Team")}
        </Space>
      </a>
    ),
    onClick: () => replace("/team/create"),
  });
  if (teamMenuItems?.length != 1) {
    itemsTeam?.unshift({
      key: "5",
      label: (
        <Typography.Text strong type="secondary" style={{ fontSize: 13 }}>
          {translate("setting.add-team", "Team wechseln")}
        </Typography.Text>
      ),
    });
  }
  const renderDrawerSider = () => {
    return (
      <>
        <SearchModal
          openDialog={searchDialog}
          handleCancel={() => setSearchDialog(false)}
        />
        <Drawer
          open={mobileSiderOpen}
          onClose={() => setMobileSiderOpen(false)}
          placement="left"
          closable={false}
          width={256}
          styles={{ body: { padding: 0 } }}
          maskClosable={true}
        >
          <Layout>
            <Layout.Sider
              width={500}
              style={{
                height: "100vh",
                borderRight: `1px solid ${token.colorBorderBg}`,
              }}
            >
              <Flex style={{ padding: "10px 0" }} justify="space-between">
                <Dropdown
                  placement="bottomRight"
                  overlayStyle={{ width: 276, padding: 6 }}
                  trigger={["click"]}
                  menu={{
                    items: [
                      {
                        key: "2",
                        label: (
                          <a
                            rel="noopener noreferrer"
                            onClick={() => {
                              list("settings", "replace");
                              setMobileSiderOpen(false);
                            }}
                          >
                            <Space>
                              <SettingOutlined />{" "}
                              {translate("settings.settings", "Settings")}
                            </Space>
                          </a>
                        ),
                      },
                      {
                        key: "4",
                        label: (
                          <a rel="noopener noreferrer">
                            <Space>
                              <StarOutlined style={{ color: "orange" }} />{" "}
                              {translate("settings.upgrade", "Upgrade to Pro")}
                            </Space>
                          </a>
                        ),
                      },
                      {
                        type: "divider",
                      },

                      ...(itemsTeam ?? []),
                      {
                        type: "divider",
                      },
                      {
                        key: "15",
                        label: (
                          <a
                            rel="noopener noreferrer"
                            onClick={() => {
                              navigateToAccount();
                              setMobileSiderOpen(false);
                            }}
                          >
                            <Space>
                              <UserOutlined />{" "}
                              {translate("buttons.my_profile", "My Profile")}
                            </Space>
                          </a>
                        ),
                      },
                      {
                        type: "divider",
                      },
                      {
                        key: "16",
                        label: (
                          <a
                            rel="noopener noreferrer"
                            onClick={() => {
                              logout();
                              setMobileSiderOpen(false);
                            }}
                          >
                            <Space>
                              <LogoutOutlined /> {translate("buttons.logout")}
                            </Space>
                          </a>
                        ),
                      },
                    ],
                  }}
                >
                  <Button type="link">
                    <Badge offset={[0, -20]}>
                      <Space align="baseline">
                        <Avatar
                          shape="circle"
                          size="default"
                          src={avatarsrc ?? imageurl}
                          style={{
                            backgroundColor:
                              avatarsrc || imageurl
                                ? "transparent"
                                : currentTeam?.metadata?.avatar?.color,
                            color: getContrastColor(
                              currentTeam?.metadata?.avatar?.color ?? "#000"
                            ),
                          }}
                        >
                          {currentTeam?.name?.charAt(0)}
                        </Avatar>
                        {!siderCollapsed && currentTeam?.name}
                        <DownOutlined style={{ fontSize: 10 }} />
                      </Space>
                    </Badge>
                  </Button>
                </Dropdown>
                <Space>
                  <Button
                    type="text"
                    onClick={() => setSiderCollapsed(!siderCollapsed)}
                    style={siderCollapsed ? { left: 10 } : {}}
                    size="large"
                  >
                    <LayoutOutlined />
                  </Button>
                </Space>
              </Flex>
              {renderMenu()}
            </Layout.Sider>
          </Layout>
        </Drawer>
      </>
    );
  };
  useKey(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_pressedKey: any, _event: any) => {
      setSearchDialog(!searchDialog);
    },
    {
      detectKeys: ["/"],
    }
  );
  const imageurl = React.useMemo(() => localStorage.getItem("avatarSrc"), []);
  if (isMobile) {
    return renderDrawerSider();
  }
  const siderStyles: React.CSSProperties = {
    borderRight: `1px solid ${token.colorBorderBg}`,
    position: "sticky",
    top: 0,
    left: 0,
    height: "100vh",
    zIndex: 999,
  };
  return (
    <>
      <SearchModal
        openDialog={searchDialog}
        handleCancel={() => setSearchDialog(false)}
      />

      <Layout.Sider
        style={siderStyles}
        width={276}
        collapsible
        collapsed={siderCollapsed}
        onCollapse={(collapsed, type) => {
          if (type === "clickTrigger") {
            setSiderCollapsed(collapsed);
          }
        }}
        collapsedWidth={80}
        breakpoint="lg"
        trigger={null}
      >
        <Flex style={{ padding: "10px 0" }} justify="space-between">
          <Dropdown
            placement="bottomRight"
            overlayStyle={{ width: 276, padding: 6 }}
            trigger={["click"]}
            menu={{
              items: [
                {
                  key: "2",
                  label: (
                    <a
                      rel="noopener noreferrer"
                      onClick={() => list("settings", "push")}
                    >
                      <Space>
                        <SettingOutlined />{" "}
                        {translate("settings.settings", "Settings")}
                      </Space>
                    </a>
                  ),
                },
                {
                  key: "4",
                  label: (
                    <a rel="noopener noreferrer">
                      <Space>
                        <StarOutlined style={{ color: "orange" }} />{" "}
                        {translate("settings.upgrade", "Upgrade to Pro")}
                      </Space>
                    </a>
                  ),
                },
                {
                  type: "divider",
                },

                ...(itemsTeam ?? []),
                {
                  type: "divider",
                },
                {
                  key: "15",
                  label: (
                    <a
                      rel="noopener noreferrer"
                      onClick={() => navigateToAccount()}
                    >
                      <Space>
                        <UserOutlined />{" "}
                        {translate("buttons.my_profile", "My Profile")}
                      </Space>
                    </a>
                  ),
                },
                {
                  type: "divider",
                },
                {
                  key: "16",
                  label: (
                    <a rel="noopener noreferrer" onClick={() => logout()}>
                      <Space>
                        <LogoutOutlined /> {translate("buttons.logout")}
                      </Space>
                    </a>
                  ),
                },
              ],
            }}
          >
            <Button type="link">
              <Badge offset={[0, -20]}>
                <Space align="baseline">
                  <Avatar
                    shape="circle"
                    size="default"
                    src={avatarsrc ?? imageurl}
                    style={{
                      backgroundColor:
                        avatarsrc || imageurl
                          ? "transparent"
                          : currentTeam?.metadata?.avatar?.color,
                      color: getContrastColor(
                        currentTeam?.metadata?.avatar?.color ?? "#000"
                      ),
                    }}
                  >
                    {currentTeam?.name?.charAt(0)}
                  </Avatar>
                  {!siderCollapsed && currentTeam?.name}
                  <DownOutlined style={{ fontSize: 10 }} />
                </Space>
              </Badge>
            </Button>
          </Dropdown>
          <Space>
            <Button
              type="text"
              onClick={() => setSiderCollapsed(!siderCollapsed)}
              style={siderCollapsed ? { left: 10 } : {}}
              size="large"
            >
              <LayoutOutlined />
            </Button>
          </Space>
        </Flex>
        {renderMenu()}
      </Layout.Sider>
    </>
  );
};
