export enum BillingType {
  OneTime = "one_time",
  Start = "start",
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
  Forever = "forever",
}
export type IProduct = {
  product: number;
  name: string;
  netto_price: number;
  currency: string;
  sales_tax: number;
  quantity?: number;
  billing_frequency: {
    unit: BillingType;
    value: number;
  };
};

export type IPhase<T = BillingType> = {
  key?: number;
  name?: string;
  title?: string;
  duration?: {
    from:
      | string
      | {
          unit: T;
          value: number;
        };
    to:
      | string
      | {
          unit: T;
          value: number;
        };
  };

  invoice: {
    unit: T;
    value: number;
  };
  product_items: IProduct[] | any;
};
