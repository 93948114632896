import { useList, useTranslate } from "@refinedev/core";
import { Button, Radio, Space } from "antd";
import React from "react";
import { currencySymbols, formatCurrency } from "../../utility";
import CustomRadio from "../form/customRadioButton";

type Props = {
  team?: any;
  disabled?: boolean;
  handleNext: () => void;
  handlePrev: () => void;
  choosenPlan: any;
  setChoosenPlan: React.Dispatch<any>;
  handleFinish?: ({ teamName }: { teamName: string }) => void;
};
export default function SelectSubscription({
  handleNext,
  handlePrev,
  disabled,
  team,
  choosenPlan,
  setChoosenPlan,
  handleFinish,
}: Props) {
  const { data } = useList({
    resource: "subscription_offerings",
    meta: {
      schema: "basejump",
    },
  });
  const offerings = data?.data;
  const translate = useTranslate();
  return (
    <Space direction="vertical">
      <Radio.Group
        buttonStyle="outline"
        style={{ width: "100%" }}
        disabled={disabled}
        onChange={(item) => {
          setChoosenPlan(item.target.value);
        }}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          {offerings &&
            offerings
              ?.sort((sortItem) => (sortItem.price == 0 ? -1 : 0))
              .map((item) => (
                <CustomRadio
                  value={item}
                  key={item.id}
                  title={item.name}
                  disabled={disabled || item.price}
                  description={
                    !item.price
                      ? "29 days free"
                      : `${currencySymbols["EUR"]} ${formatCurrency(
                          item.price ?? 0
                        )} / ${item.interval ?? ""} ${translate(
                          "team.per_member"
                        )}`
                  }
                />
              ))}
        </Space>
      </Radio.Group>
      <Space>
        <Button
          style={{ marginLeft: "0.3rem" }}
          onClick={() => handlePrev()}
          disabled={disabled}
        >
          {translate("buttons.previous")}
        </Button>
        {choosenPlan?.price != "test" ? (
          <Button
            type="primary"
            onClick={() => handleFinish?.({ teamName: team })}
            disabled={disabled}
            // loading={isLoading}
          >
            {translate("teams.create_team_button")}
          </Button>
        ) : (
          <Button
            type="primary"
            onClick={() => handleNext()}
            disabled={disabled}
          >
            {translate("button.next")}
          </Button>
        )}
      </Space>
    </Space>
  );
}
