import { BaseRecord, useTranslate } from "@refinedev/core";
import { Button, Grid, Space, Table } from "antd";
import React, { useState } from "react";
import { useUser } from "../../../contexts/ContextProvider";
import { DeleteButton, EditButton, List, useTable } from "@refinedev/antd";
import { PlusOutlined } from "@ant-design/icons";
import CreateOrUpdate from "./create-update-segment";

const { useBreakpoint } = Grid;

export default function SegmentList() {
  const [openModal, setOpenModal] = useState<{
    id?: number | string;
    open: boolean;
  }>();
  const translate = useTranslate();
  const screens = useBreakpoint();
  const { currentTeam } = useUser();

  const { tableProps } = useTable({
    resource: "segments",
    syncWithLocation: false,
    meta: {
      select: "*",
    },

    filters: {
      permanent: [
        {
          field: "account",
          value: currentTeam?.account_id,
          operator: "eq",
        },
      ],
    },
  });

  return (
    <>
      <List
        headerButtons={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setOpenModal({ open: true })}
          >
            Neue Segmenten anlegen
          </Button>
        }
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="name" title={"Name"} />
          <Table.Column
            title={translate("tags.actions")}
            dataIndex="actions"
            width={200}
            render={(_, record: BaseRecord) => (
              <Space>
                <EditButton
                  hideText
                  size={screens.sm ? "small" : "middle"}
                  recordItemId={record.id}
                  onClick={() => setOpenModal({ open: true, id: record.id })}
                />
                <DeleteButton
                  hideText
                  size={screens.sm ? "small" : "middle"}
                  recordItemId={record.id}
                  resource="segments"
                />
              </Space>
            )}
          />
        </Table>
      </List>
      {openModal?.open && (
        <CreateOrUpdate
          {...openModal}
          onClose={() => setOpenModal({ open: false })}
        />
      )}
    </>
  );
}
