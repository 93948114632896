import { CheckCircleOutlined, WarningFilled } from "@ant-design/icons";
import { QueryBuilderAntD } from "@react-querybuilder/antd";
import { useShow, useTranslate } from "@refinedev/core";
import { Button, Card, Flex, Form, Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { BuilderContext, NodeContext, useDrawer } from "react-flow-builder";
import {
  QueryBuilder,
  RuleGroupType,
  formatQuery,
  type RuleType,
} from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";
import { parseJsonLogic } from "react-querybuilder/parseJsonLogic";
import { useParams } from "react-router-dom";
import { Text } from "../../text";
import "./Condition.css";

export const ConditionDisplay: React.FC = () => {
  const node = useContext(NodeContext);
  useContext(NodeContext);
  return (
    <>
      <Card
        size={"small"}
        title={
          <Flex justify={"space-between"}>
            <Space>
              <CheckCircleOutlined />
              <Text>Condition</Text>
            </Space>
            {node.validateStatusError ? (
              <WarningFilled style={{ color: "orange" }} />
            ) : null}
          </Flex>
        }
        style={{ width: 300 }}
      >
        <p>My Condition</p>
      </Card>
    </>
  );
};

export const ConditionForm: React.FC = () => {
  const { selectedNode: node } = useContext(BuilderContext);
  const { closeDrawer: cancel, saveDrawer: save } = useDrawer();

  const [form] = Form.useForm();
  const initialQuery: RuleGroupType = { combinator: "and", rules: [] };
  const [query, setQuery] = useState(initialQuery);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      values.json_logic = formatQuery(query, "jsonlogic");
      save?.(values);
    } catch (error) {
      const values = form.getFieldsValue();
      save?.(values, !!error);
    }
  };
  const translate = useTranslate();

  const params = useParams();

  const { queryResult: stageResult, setShowId } = useShow({
    resource: "pipeline_stages",
    meta: {
      select: "*, pipeline(custom_fields_json, custom_fields_ui) as pipeline",
    },
  });

  const validator = (r: RuleType) => !!r.value;
  const [fields, setFields] = useState([
    {
      name: "pipelineItem.id",
      label: "Pipeline Item ID",
      inputType: "number",
      placeholder: "Enter pipeline item id",
      validator,
    },
  ]);

  useEffect(() => {
    setShowId(params.pipelineStageId);
  }, [params.pipelineStageId, setShowId]);

  useEffect(() => {
    if (stageResult.data?.data.pipeline?.custom_fields_json?.properties) {
      const obj = stageResult.data.data.pipeline.custom_fields_json.properties;
      Object.keys(obj).forEach((key) => {
        setFields((currentFields) => [
          ...currentFields,
          {
            name: "custom_fields." + key,
            label: obj[key].title,
            placeholder: "Enter " + obj[key].title,
            inputType: obj[key].type === "integer" ? "number" : "text",
            validator,
          },
        ]);
      });
    }
  }, [stageResult.data?.data.pipeline?.custom_fields_json?.properties]);

  useEffect(() => {
    if (node?.data?.json_logic) {
      setQuery(parseJsonLogic(node.data.json_logic));
    }
  }, [node?.data?.json_logic]);

  // const fields = (
  //   [
  //     {
  //       name: "firstName",
  //       label: "First Name",
  //       placeholder: "Enter first name",
  //       validator,
  //     },
  //     {
  //       name: "lastName",
  //       label: "Last Name",
  //       placeholder: "Enter last name",
  //       defaultOperator: "beginsWith",
  //       validator,
  //     },
  //     { name: "age", label: "Age", inputType: "number", validator },
  //     {
  //       name: "isMusician",
  //       label: "Is a musician",
  //       valueEditorType: "checkbox",
  //       operators: defaultOperators.filter((op) => op.name === "="),
  //       defaultValue: false,
  //     },
  //     {
  //       name: "instrument",
  //       label: "Primary instrument",
  //       valueEditorType: "select",
  //       values: musicalInstruments,
  //       defaultValue: "Cowbell",
  //       operators: defaultOperators.filter((op) => op.name === "="),
  //     },
  //     {
  //       name: "alsoPlays",
  //       label: "Also plays",
  //       valueEditorType: "multiselect",
  //       values: musicalInstruments,
  //       defaultValue: "More cowbell",
  //       operators: defaultOperators.filter((op) => op.name === "in"),
  //     },
  //     {
  //       name: "gender",
  //       label: "Gender",
  //       operators: defaultOperators.filter((op) => op.name === "="),
  //       valueEditorType: "radio",
  //       values: [
  //         { name: "M", label: "Male" },
  //         { name: "F", label: "Female" },
  //         { name: "O", label: "Other" },
  //       ],
  //     },
  //     { name: "height", label: "Height", validator },
  //     { name: "job", label: "Job", validator },
  //     {
  //       name: "description",
  //       label: "Description",
  //       valueEditorType: "textarea",
  //     },
  //     { name: "birthdate", label: "Birth Date", inputType: "date" },
  //     { name: "datetime", label: "Show Time", inputType: "datetime-local" },
  //     { name: "alarm", label: "Daily Alarm", inputType: "time" },
  //     {
  //       name: "groupedField1",
  //       label: "Grouped Field 1",
  //       comparator: "groupNumber",
  //       groupNumber: "group1",
  //       valueSources: ["field", "value"],
  //     },
  //     {
  //       name: "groupedField2",
  //       label: "Grouped Field 2",
  //       comparator: "groupNumber",
  //       groupNumber: "group1",
  //       valueSources: ["field", "value"],
  //     },
  //     {
  //       name: "groupedField3",
  //       label: "Grouped Field 3",
  //       comparator: "groupNumber",
  //       groupNumber: "group1",
  //       valueSources: ["field", "value"],
  //     },
  //     {
  //       name: "groupedField4",
  //       label: "Grouped Field 4",
  //       comparator: "groupNumber",
  //       groupNumber: "group1",
  //       valueSources: ["field", "value"],
  //     },
  //   ] satisfies Field[]
  // ).map(toFullOption);

  return (
    <div>
      <QueryBuilderAntD>
        <QueryBuilder
          fields={fields}
          query={query}
          onQueryChange={setQuery}
          showShiftActions
        />
      </QueryBuilderAntD>
      <div>
        <Space>
          <Button onClick={cancel}>{translate("buttons.cancel")}</Button>
          <Button type="primary" onClick={handleSubmit}>
            {translate("buttons.save")}
          </Button>
        </Space>
      </div>
    </div>
  );
};
