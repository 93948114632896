import React from "react";
import { supabaseClient } from "../utility";
import { generateRandomSlug } from "../utility/generalFunctions";

export default function useTeamFn() {
  const [isLoading, setIsLoading] = React.useState(false);

  const createTeam = async (value: { name: string }) => {
    setIsLoading(true);

    try {
      const { data } = await supabaseClient.rpc("create_account", {
        name: value.name,
        slug: generateRandomSlug(),
      });
      setIsLoading(false);
      return data;
    } catch (error: any) {
      if (error.code == "P0001") {
        createTeam(value);
      }
      setIsLoading(false);
      throw error;
    }
  };
  return { isLoading, createTeam };
}
