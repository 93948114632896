import { Authenticated, CanAccess, Refine } from "@refinedev/core";
import { DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import { Button, ConfigProvider, Modal } from "antd";
import deDE from "antd/locale/de_DE";
import enUS from "antd/locale/en_US";
import React, { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import "./i18n";

import {
  AuthPage,
  ErrorComponent,
  ThemedLayoutV2,
  ThemedTitleV2,
  useNotificationProvider,
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";

import {
  ApartmentOutlined,
  BranchesOutlined,
  DashboardOutlined,
  MailOutlined,
  SettingOutlined,
  ShopOutlined,
  UserOutlined,
} from "@ant-design/icons";
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { dataProvider, liveProvider } from "@refinedev/supabase";
import { App as AntdApp } from "antd";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import authProvider from "./authProvider";
import { AppIcon } from "./components/app-icon";
import { ColorModeContextProvider } from "./contexts/color-mode";
// import usePipelines from "./hooks/usePipelines";
import * as Sentry from "@sentry/react";
import Layout from "./components/Layout/Layout";
import { Sider } from "./components/Layout/sider";
import { RegisterPage } from "./components/auth/RegisterPage";
import VerifyPage from "./components/auth/VerifyPage";
import { HeaderV2 } from "./components/header/HeaderV2";
import InvoicesList from "./components/settings/InvoicesList";
import MailingSettings from "./components/settings/MailingSettings";
import ShopSettings from "./components/settings/ShopSettings";
import SubscriptionContracts from "./components/teams/settings/subscription-contracts";
import TeamSettings from "./components/teams/settings/team-settings";
import { UserProvider } from "./contexts/ContextProvider/userProvider";
import { usePipelineContext } from "./contexts/PipelineProvider";
import AutomationsCreate from "./pages/automations/create";
import AutomationsEdit from "./pages/automations/edit";
import AutomationsList from "./pages/automations/list";
import CreateProductItem from "./pages/shop/product-items/create";
import ProductItemShow from "./pages/shop/product-items/show";
import SubscriptionProductsCreate from "./pages/shop/subscription-products/create";
import SubscriptionProductsList from "./pages/shop/subscription-products/list";
import SubscriptionProductsShow from "./pages/shop/subscription-products/show";
import SubscriptionCreate from "./pages/shop/subscriptions/create";
import SubscriptionsList from "./pages/shop/subscriptions/list";
import SubscriptionsShow from "./pages/shop/subscriptions/show";
import TeamMembersList from "./pages/team-members/list";
import CreateTeam from "./pages/team/create";
import AccountSetup from "./pages/welcome/AccountSetup";
import "./styles/antd.css";
import "./styles/fc.css";
import "./styles/index.css";
import { supabaseClient } from "./utility";
import { RedirectHomeRoute } from "./utility/RedirectHomeRoute";
import { ValidTeamChecker } from "./utility/ValidTeamChecker";
import { capitalizeEveryWord } from "./utility/transform";

const SettingsList = React.lazy(() => import("./pages/settings"));
const SalesKPIList = React.lazy(
  () => import("./pages/backoffice/SalesKPI/list")
);

const ProductCategorieList = React.lazy(
  () => import("./pages/shop/product-categories/list")
);
const CreateProductCategory = React.lazy(
  () => import("./pages/shop/product-categories/create")
);
const EditProductCategory = React.lazy(
  () => import("./pages/shop/product-categories/edit")
);
const ProductItemList = React.lazy(
  () => import("./pages/shop/product-items/list")
);
const FileList = React.lazy(() => import("../src/pages/settings/files/list"));
const PipelineItemEdit = React.lazy(
  () => import("../src/pages/pipelines/items/edit")
);
const PipelineItemCreate = React.lazy(
  () => import("../src/pages/pipelines/items/create")
);
const PipelineItemShow = React.lazy(
  () => import("../src/pages/pipelines/items/show/index")
);
const PipelineShow = React.lazy(() => import("../src/pages/pipelines/show"));
const StagesShow = React.lazy(
  () => import("../src/pages/pipelines/stages/show")
);
const PipelineEdit = React.lazy(() => import("../src/pages/pipelines/edit"));
const PipelineAutomationEdit = React.lazy(
  () => import("../src/pages/pipelines/automation/edit")
);
const PipelineStoreShow = React.lazy(
  () => import("../src/pages/pipelines/pipelines-store/show")
);
const PipelineStoreList = React.lazy(
  () => import("../src/pages/pipelines/pipelines-store/list")
);

const PipelineList = React.lazy(() => import("../src/pages/pipelines/list"));
const TeamCreate = React.lazy(
  () => import("../src/pages/settings/team/create")
);
const AccountShow = React.lazy(() => import("../src/pages/account/show"));
const TagList = React.lazy(() => import("../src/pages/settings/tags/list"));
const TagShow = React.lazy(() => import("../src/pages/settings/tags/show"));
const TagEdit = React.lazy(() => import("../src/pages/settings/tags/edit"));
const MailingsCreate = React.lazy(
  () => import("./pages/mailings/info-mails/create")
);
const MailingsList = React.lazy(
  () => import("./pages/mailings/info-mails/list")
);
const MailingsEdit = React.lazy(
  () => import("./pages/mailings/info-mails/edit")
);
const MailingsShow = React.lazy(
  () => import("./pages/mailings/info-mails/show")
);
const ContactEdit = React.lazy(() => import("../src/pages/contacts/edit"));
const ContactShow = React.lazy(() => import("../src/pages/contacts/show"));
const ContactCreate = React.lazy(() => import("../src/pages/contacts/create"));
const DashboardPage = React.lazy(() => import("../src/pages/dashboard/index"));
const ContactList = React.lazy(() => import("../src/pages/contacts/list"));

function App() {
  // const { pipelines } = usePipelines({
  //   provider: dataProvider(supabaseClient),
  // });
  const { pipelines, canAccess } = usePipelineContext();
  const { t, i18n } = useTranslation();
  const i18nProvider = {
    translate: (key: string, params: any) => t(key, params as string),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };
  const [isPipelineSettingsModalOpen, setIsPipelineSettingsModalOpen] =
    useState(false);
  if (process.env.NODE_ENV === "production") {
    Sentry.init({
      environment: process.env.NODE_ENV,
      dsn: "https://fa3cb8c0aea9b4c8f23595b18b560fee@o1271716.ingest.us.sentry.io/4507447284727808",
      integrations: [
        Sentry.browserTracingIntegration(),
        // Sentry.replayIntegration({
        //   maskAllText: false,
        //   blockAllMedia: false,
        // }),
        Sentry.feedbackIntegration({
          colorScheme: "system",
          triggerLabel: t("sentry.feedback.triggerLabel"),
          formTitle: t("sentry.feedback.formTitle"),
          nameLabel: t("sentry.feedback.nameLabel"),
          namePlaceholder: t("sentry.feedback.namePlaceholder"),
          emailLabel: t("sentry.feedback.emailLabel"),
          emailPlaceholder: t("sentry.feedback.emailPlaceholder"),
          messageLabel: t("sentry.feedback.messageLabel"),
          messagePlaceholder: t("sentry.feedback.messagePlaceholder"),
          isRequiredLabel: t("sentry.feedback.isRequiredLabel"),
          addScreenshotButtonLabel: t(
            "sentry.feedback.addScreenshotButtonLabel"
          ),
          removeScreenshotButtonLabel: t(
            "sentry.feedback.removeScreenshotButtonLabel"
          ),
          submitButtonLabel: t("sentry.feedback.submitButtonLabel"),
          cancelButtonLabel: t("sentry.feedback.cancelButtonLabel"),
          showBranding: false,
        }),
      ],
      tracesSampleRate: 0.0,
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
  return (
    <ConfigProvider
      locale={t("page.settings.locale") === "de" ? deDE : enUS}
      theme={{
        components: {
          Layout: {
            bodyBg: "#f9fafc",
            headerBg: "#f6f7f8",
            //siderBg: "#096DD9",
            siderBg: "#ffffff",
          },
          Card: {
            borderRadiusLG: 4,
          },
          List: {
            metaMarginBottom: 40,
            titleMarginBottom: 40,
          },
          Drawer: {
            colorBgElevated: "rgba(245, 247, 249, 1)",
          },
          Menu: {
            itemSelectedBg: "rgba(25, 119, 242, 0.1)",
            itemSelectedColor: "rgba(25, 119, 242, 1)",
            itemColor: "#202020",
            itemHoverColor: "rgba(25, 119, 242, 1)",
            itemHoverBg: "rgba(25, 119, 242, 0.1)",
            subMenuItemBg: "rgba(0, 0, 0, 0)",
            // popupBg: "#096DD9",
            popupBg: "#FCFAF8",
            itemActiveBg: "rgba(0, 0, 0, 0.09)",
          },
          Radio: {
            buttonSolidCheckedBg: "#1890FF",
          },
          Table: {
            colorBgContainer: "#fff",
            //headerBg: "rgba(245, 247, 249, 1)",
            headerBg: "rgba(232, 241, 254, 1)",
            borderColor: "#F5F5F5",
            headerBorderRadius: 0,
          },
          Button: {
            colorPrimary: "#1890FF",
            controlHeight: 40,
          },
          Tabs: {
            colorFillAlter: "#fff",
            margin: 0,
            cardGutter: 8,
            colorBorderSecondary: "rgba(240, 240, 240, 1)",
            itemSelectedColor: "#000",
            itemColor: "#595959",
            colorBgContainer: "rgba(232, 241, 254, 1)",
          },
          Input: {
            colorBgContainerDisabled: "#F3F8FD",
            colorTextDisabled: "#98A8B9",
          },
          Select: {
            colorTextDisabled: "#98A8B9",
            colorBgContainerDisabled: "#F3F8FD",
          },
          Modal: {
            colorBgMask: "rgba(0,0,0,0.08)",
          },
          Timeline: {
            dotBg: "transparent",
          },
        },
      }}
    >
      <BrowserRouter>
        <RefineKbarProvider>
          <ColorModeContextProvider>
            <AntdApp>
              <DevtoolsProvider>
                <Refine
                  dataProvider={{
                    default: dataProvider(supabaseClient),
                  }}
                  liveProvider={liveProvider(supabaseClient)}
                  authProvider={authProvider}
                  accessControlProvider={{
                    can: async ({ resource, action }) => {
                      if (
                        (resource === "pipelines" ||
                          resource === "pipelines-menu") &&
                        action === "list" &&
                        (canAccess?.modules?.pipeline === false ||
                          canAccess?.modules?.pipeline === undefined)
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      if (
                        (resource === "pipelines" ||
                          resource === "pipelines-menu") &&
                        action === "list" &&
                        canAccess?.modules?.pipeline === true &&
                        canAccess?.personal_modules?.pipeline === false
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      if (
                        (resource === "shop" ||
                          resource === "product-items" ||
                          resource === "subscription_products" ||
                          resource === "subscriptions") &&
                        action === "list" &&
                        (canAccess?.modules?.shop === false ||
                          canAccess?.modules?.shop === undefined)
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      if (
                        (resource === "shop" ||
                          resource === "product-items" ||
                          resource === "subscription_products" ||
                          resource === "subscriptions") &&
                        action === "list" &&
                        canAccess?.personal_modules?.shop === false
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      if (
                        (resource === "mailings" ||
                          resource === "info_mails" ||
                          resource === "newsletter") &&
                        action === "list" &&
                        (canAccess?.modules?.mailing === false ||
                          canAccess?.modules?.shop === undefined)
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      if (
                        (resource === "mailings" ||
                          resource === "info_mails" ||
                          resource === "newsletter") &&
                        action === "list" &&
                        canAccess?.personal_modules?.mailing === false
                      ) {
                        return {
                          can: false,
                          reason: "Unauthorized",
                        };
                      }
                      return { can: true };
                    },
                  }}
                  routerProvider={routerBindings}
                  notificationProvider={useNotificationProvider}
                  i18nProvider={i18nProvider}
                  resources={[
                    {
                      name: "dashboard",
                      list: "/:teamSlug",
                      meta: {
                        label: "Dashboard",
                        icon: <DashboardOutlined />,
                      },
                    },
                    {
                      name: "contacts",
                      list: "/:teamSlug/contacts",
                      create: "/:teamSlug/contacts/create",
                      edit: "/:teamSlug/contacts/edit/:id",
                      show: "/:teamSlug/contacts/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <UserOutlined />,
                      },
                    },
                    {
                      name: "pipelines-menu",
                      edit: "/:teamSlug/pipelines/show/:pipelineId/edit",
                      meta: {
                        icon: <ApartmentOutlined />,
                        label: "Pipelines",
                        secondaryButton: (
                          <>
                            <Button
                              type="link"
                              className={"showOnHoverChild"}
                              onClick={(e) => {
                                e.stopPropagation();

                                setIsPipelineSettingsModalOpen(true);

                                // Todo: The following line does not work as expected, please open real pipeline store modal
                                //list("pipelines-store", "push");

                                return false;
                              }}
                            >
                              +
                            </Button>
                          </>
                        ),
                        modal: (
                          <Modal
                            title={t("pipelines.pipelines", "Pipelines")}
                            open={isPipelineSettingsModalOpen}
                            onOk={() => setIsPipelineSettingsModalOpen(false)}
                            onCancel={() =>
                              setIsPipelineSettingsModalOpen(false)
                            }
                          >
                            We want to show the list of Pipelines here together
                            with a trash icon to delete and the possibility to
                            resort the order and add another button to add a new
                            pipeline.
                            {/*<PipelineList />*/}
                          </Modal>
                        ),
                      },
                    },
                    ...pipelines,
                    {
                      name: "shop",
                      list: "/:teamSlug/shop",
                      meta: {
                        icon: <ShopOutlined />,
                        label: t("commerce.title", "Commerce"),
                      },
                    },

                    {
                      name: "product-categories",
                      list: "/:teamSlug/shop/product-categories",
                      create: "/:teamSlug/shop/product-categories/create",
                      edit: "/:teamSlug/shop/product-categories/edit/:id",
                      show: "/:teamSlug/shop/product-categories/show/:id",

                      meta: {
                        canDelete: true,
                        parent: "shop",
                        label: t(
                          "commerce.product_categories",
                          "Product categories"
                        ),
                      },
                    },
                    {
                      name: "product-items",
                      list: "/:teamSlug/shop/product-items",
                      create: "/:teamSlug/shop/product-items/create",
                      edit: "/:teamSlug/shop/product-items/edit/:id",
                      show: "/:teamSlug/shop/product-items/show/:id",

                      meta: {
                        canDelete: true,
                        parent: "shop",
                        label: t("commerce.product_items", "Product items"),
                      },
                    },
                    {
                      name: "subscription_products",
                      list: "/:teamSlug/shop/subscription-products",
                      create: "/:teamSlug/shop/subscription-products/create",
                      edit: "/:teamSlug/shop/subscription-products/edit/:id",
                      show: "/:teamSlug/shop/subscription-products/show/:id",

                      meta: {
                        canDelete: true,
                        parent: "shop",
                        label: t("commerce.subscriptions", "Subscriptions"),
                      },
                    },
                    {
                      name: "subscriptions",
                      list: "/:teamSlug/shop/subscriptions",
                      create: "/:teamSlug/shop/subscriptions/create",
                      edit: "/:teamSlug/shop/subscriptions/edit/:id",
                      show: "/:teamSlug/shop/subscriptions/show/:id",

                      meta: {
                        canDelete: true,
                        parent: "shop",
                        label: t("commerce.subscribers", "Subscribers"),
                      },
                    },
                    {
                      name: "mailings",

                      meta: {
                        icon: <MailOutlined />,
                      },
                    },

                    {
                      name: "info_mails",
                      list: "/:teamSlug/mailings/info_mails",
                      create: "/:teamSlug/mailings/info_mails/create",
                      edit: "/:teamSlug/mailings/info_mails/edit/:id",
                      show: "/:teamSlug/mailings/info_mails/show/:id",

                      meta: {
                        canDelete: true,
                        parent: "mailings",
                        label: "Info-Mails",
                      },
                    },
                    {
                      name: "newsletter",
                      list: "/:teamSlug/mailings/newsletter",
                      create: "/:teamSlug/mailings/newsletter/create",
                      edit: "/:teamSlug/mailings/newsletter/edit/:id",
                      show: "/:teamSlug/mailings/newsletter/show/:id",

                      meta: {
                        canDelete: true,
                        parent: "mailings",
                        label: "Newsletter",
                      },
                    },

                    {
                      name: "pipeline_items",
                      list: "/:teamSlug/pipelines/show/:pipelineId/items",
                      create:
                        "/:teamSlug/pipelines/show/:pipelineId/items/create",
                      show: "/:teamSlug/pipelines/show/:pipelineId/items/:id",
                      edit: "/:teamSlug/pipelines/show/:pipelineId/items/edit:id",
                      meta: {
                        hide: true,
                        parent: "pipelines",
                      },
                    },

                    {
                      name: "settings",
                      list: "/:teamSlug/settings",
                      meta: { icon: <SettingOutlined />, hide: true },
                    },
                    {
                      name: "account",
                      list: "/:teamSlug/account",
                      meta: {
                        label: t("settings.account"),
                        hide: true,
                      },
                    },
                    {
                      name: "team",
                      list: "/:teamSlug/settings/team",
                      meta: {
                        parent: "settings",
                        label: t("settings.team"),
                        hide: true,
                      },
                    },

                    {
                      name: "pipelines",
                      list: "/:teamSlug/settings/pipelines",
                      create: "/:teamSlug/settings/pipelines/create",
                      edit: "/:teamSlug/pipelines/show/:pipelineId/settings",
                      meta: {
                        parent: "settings",
                        canDelete: true,
                        hide: true,
                      },
                    },
                    {
                      name: "pipeline_stages_custom_field",
                      edit: "/:teamSlug/pipelines/show/:pipelineId/settings/custom-fields",
                      meta: {
                        parent: "settings",
                        hide: true,
                      },
                    },
                    {
                      name: "pipelines-store",
                      list: "/:teamSlug/settings/pipelines/store",
                      show: "/:teamSlug/settings/pipelines/store/show/:id",
                      meta: {
                        parent: "pipelines",
                        hide: true,
                      },
                    },
                    {
                      name: "pipeline_stages",
                      show: "/:teamSlug/pipelines/show/:pipelineId/:stageId",
                    },
                    {
                      name: "groups",
                      list: "/:teamSlug/settings/groups",
                      create: "/:teamSlug/settings/groups/create",
                      edit: "/:teamSlug/settings/groups/edit/:id",
                      show: "/:teamSlug/settings/groups/show/:id",
                      meta: {
                        parent: "settings",
                        canDelete: true,
                        hide: true,
                      },
                    },
                    {
                      name: "files",
                      list: "/:teamSlug/settings/files",
                      meta: { parent: "settings", hide: true },
                    },
                    {
                      name: "team-members",
                      list: "/:teamSlug/team-members",
                      meta: { hide: true },
                    },
                    {
                      name: "sales-kpi",
                      list: "/:teamSlug/sales-kpi",
                      meta: {
                        label: "Sales KPI's",
                        hide: true,
                      },
                    },
                    {
                      name: "automations",
                      list: "/:teamSlug/automations",
                      create: "/:teamSlug/automations/create",
                      edit: "/:teamSlug/automations/edit/:id",
                      show: "/:teamSlug/automations/show/:id",
                      meta: {
                        canDelete: true,
                        icon: <BranchesOutlined />,
                      },
                    },
                  ]}
                  options={{
                    syncWithLocation: true,
                    warnWhenUnsavedChanges: true,
                    useNewQueryKeys: true,
                    projectId: "m5CFOK-FLhH7r-EYpiQH",
                    liveMode: "auto",
                    disableTelemetry: true,
                  }}
                >
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <Authenticated
                          key="authenticated-inner"
                          fallback={<CatchAllNavigate to="/login" />}
                        >
                          <RedirectHomeRoute />
                        </Authenticated>
                      }
                    />
                    <Route
                      element={
                        <Authenticated
                          key="authenticated-inner"
                          fallback={<CatchAllNavigate to="/login" />}
                        >
                          <UserProvider>
                            <Layout>
                              <ThemedLayoutV2
                                Header={() => <HeaderV2 />}
                                Sider={() => (
                                  // <Layout>
                                  <Sider />
                                  // </Layout>
                                )}
                                Title={({ collapsed }) => (
                                  <ThemedTitleV2
                                    collapsed={collapsed}
                                    text="CRM"
                                    icon={<AppIcon />}
                                  />
                                )}
                              >
                                <Outlet />
                              </ThemedLayoutV2>
                            </Layout>
                          </UserProvider>
                        </Authenticated>
                      }
                    >
                      <Route
                        path="/:teamSlug"
                        element={
                          <ValidTeamChecker>
                            <Outlet />
                          </ValidTeamChecker>
                        }
                      >
                        <Route
                          index
                          element={
                            // <Layout>
                            <Suspense>
                              <DashboardPage />
                            </Suspense>
                            // </Layout>
                          }
                        />
                        <Route
                          path="contacts"
                          element={
                            // <Layout action="list" resource="contacts">
                            <Suspense>
                              <CanAccess
                                resource="contacts"
                                action="list"
                                fallback={t("module.noAccessTitle")}
                              >
                                <ContactList>
                                  <Outlet />
                                </ContactList>
                              </CanAccess>
                            </Suspense>
                            // </Layout>
                          }
                        >
                          <Route index element={null} />
                          <Route
                            path="create"
                            element={
                              // <Layout action="create" resource="contacts">
                              <Suspense>
                                <ContactCreate>
                                  <Outlet />
                                </ContactCreate>
                              </Suspense>
                              // </Layout>
                            }
                          />
                          <Route
                            path="edit/:id"
                            element={
                              // <Layout action="edit" resource="contacts">
                              <Suspense>
                                <ContactEdit />
                              </Suspense>
                              // </Layout>
                            }
                          />
                          <Route
                            path="show/:id"
                            element={
                              // <Layout action="show" resource="contacts">
                              <Suspense>
                                <ContactShow>
                                  <Outlet />
                                </ContactShow>
                              </Suspense>
                              // </Layout>
                            }
                          >
                            <Route
                              path="company-create"
                              element={
                                // <Layout action="create" resource="contacts">
                                <Suspense>
                                  <PipelineItemCreate />
                                </Suspense>
                                // </Layout>
                              }
                            />
                          </Route>
                        </Route>
                        <Route
                          path="shop"
                          element={
                            <CanAccess fallback={t("module.noAccessTitle")}>
                              <Outlet />
                            </CanAccess>
                          }
                        >
                          <Route
                            path="product-categories"
                            element={
                              <Suspense>
                                <ProductCategorieList />
                                <Outlet />
                              </Suspense>
                            }
                          >
                            <Route index element={null} />

                            <Route
                              path="create"
                              element={
                                <Suspense>
                                  <CreateProductCategory />
                                </Suspense>
                              }
                            />
                            <Route
                              path="edit/:id"
                              element={
                                <Suspense>
                                  <EditProductCategory />
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route
                            path="product-items"
                            element={
                              <Suspense>
                                <ProductItemList />
                                <Outlet />
                              </Suspense>
                            }
                          >
                            <Route index element={null} />
                            <Route
                              path="show/:id"
                              element={
                                // <Layout action="show" resource="product-item">
                                <Suspense>
                                  <ProductItemShow>
                                    <Outlet />
                                  </ProductItemShow>
                                </Suspense>
                                // </Layout>
                              }
                            />
                            <Route
                              path="create"
                              element={<CreateProductItem />}
                            />
                          </Route>
                          <Route
                            path="subscription-products"
                            element={
                              // <Layout
                              //   action="list"
                              //   resource="subscription-products"
                              // >
                              <Suspense>
                                <SubscriptionProductsList>
                                  <Outlet />
                                </SubscriptionProductsList>
                              </Suspense>
                              // </Layout>
                            }
                          >
                            <Route index element={null} />
                            <Route
                              path="create"
                              element={
                                // <Layout
                                //   action="create"
                                //   resource="subscription_products"
                                // >
                                <Suspense>
                                  <SubscriptionProductsCreate>
                                    <Outlet />
                                  </SubscriptionProductsCreate>
                                </Suspense>
                                // </Layout>
                              }
                            />
                            <Route
                              path="show/:id"
                              element={
                                // <Layout
                                //   action="show"
                                //   resource="subscription-products"
                                // >
                                <Suspense>
                                  <SubscriptionProductsShow>
                                    <Outlet />
                                  </SubscriptionProductsShow>
                                </Suspense>
                                // </Layout>
                              }
                            />
                          </Route>
                          <Route
                            path="subscriptions"
                            element={
                              // <Layout action="list" resource="subscriptions">
                              <Suspense>
                                <SubscriptionsList>
                                  <Outlet />
                                </SubscriptionsList>
                              </Suspense>
                              // </Layout>
                            }
                          >
                            <Route index element={null} />
                            <Route
                              path="create"
                              element={
                                // <Layout
                                //   action="create"
                                //   resource="subscriptions"
                                // >
                                <Suspense>
                                  <SubscriptionCreate />
                                </Suspense>
                                // </Layout>
                              }
                            />
                            <Route
                              path="show/:id"
                              element={
                                // <Layout action="show" resource="subscriptions">
                                <Suspense>
                                  <SubscriptionsShow>
                                    <Outlet />
                                  </SubscriptionsShow>
                                </Suspense>
                                // </Layout>
                              }
                            />
                          </Route>
                        </Route>
                        <Route
                          path="mailings"
                          element={
                            <CanAccess fallback={t("module.noAccessTitle")}>
                              <Outlet />
                            </CanAccess>
                          }
                        >
                          <Route
                            path="info_mails"
                            element={
                              // <Layout>
                              <Suspense>
                                <MailingsList />
                              </Suspense>
                              // </Layout>
                            }
                          />
                          <Route
                            path="info_mails/show/:id"
                            element={
                              // <Layout action="show" resource="info_mails">
                              <Suspense>
                                <MailingsShow />
                              </Suspense>
                              // </Layout>
                            }
                          />
                          <Route
                            path="info_mails/edit/:id"
                            element={
                              // <Layout action="edit" resource="info_mails">
                              <Suspense>
                                <MailingsEdit />
                              </Suspense>
                              // </Layout>
                            }
                          />
                          <Route
                            path="info_mails/create"
                            element={
                              // <Layout action="create" resource="info_mails">
                              <Suspense>
                                <MailingsCreate />
                              </Suspense>
                              // </Layout>
                            }
                          />
                        </Route>

                        <Route
                          index
                          element={<NavigateToResource resource="tags" />}
                        />

                        <Route
                          element={
                            <Suspense>
                              <SettingsList>
                                <Outlet />
                              </SettingsList>
                            </Suspense>
                          }
                          path="settings"
                        >
                          <Route index element={null} />
                          <Route
                            path="subscription-contracts"
                            element={
                              <Suspense>
                                <SubscriptionContracts>
                                  <Outlet />
                                </SubscriptionContracts>
                              </Suspense>
                            }
                          />
                          <Route
                            path="invoices"
                            element={
                              <Suspense>
                                <InvoicesList>
                                  <Outlet />
                                </InvoicesList>
                              </Suspense>
                            }
                          />
                          <Route
                            path="team"
                            element={
                              <Suspense>
                                <TeamSettings>
                                  <Outlet />
                                </TeamSettings>
                              </Suspense>
                            }
                          />
                          <Route
                            path="team/create"
                            element={
                              <Suspense>
                                <TeamCreate />
                              </Suspense>
                            }
                          />
                          <Route
                            path="shop"
                            element={
                              <Suspense>
                                <ShopSettings />
                              </Suspense>
                            }
                          />
                          <Route
                            path="mailing"
                            element={
                              <Suspense>
                                <MailingSettings />
                              </Suspense>
                            }
                          />
                          <Route path="pipelines">
                            <Route
                              element={
                                // <Layout action="list" resource="pipelines">
                                <Suspense>
                                  <CanAccess
                                    resource="pipelines"
                                    action="settings"
                                    fallback={t("module.noAccessTitle")}
                                  >
                                    <PipelineList>
                                      <Outlet />
                                    </PipelineList>
                                  </CanAccess>
                                </Suspense>
                                // </Layout>
                              }
                            >
                              <Route index element={null} />
                              {/* <Route
                                path="create"
                                element={
                                  // <Layout action="create" resource="pipelines">
                                  <Suspense>
                                    <PipelineCreate>
                                      <Outlet />
                                    </PipelineCreate>
                                  </Suspense>
                                  // </Layout>
                                }
                              /> */}
                            </Route>
                            <Route
                              path="edit/:id"
                              element={
                                // <Layout action="edit" resource="pipelines">
                                <Suspense>
                                  <PipelineEdit />
                                </Suspense>
                                // </Layout>
                              }
                            />
                            <Route
                              path="store"
                              element={
                                <Suspense>
                                  <PipelineStoreList>
                                    <Outlet />
                                  </PipelineStoreList>
                                </Suspense>
                              }
                            >
                              <Route
                                path="show/:storePipelineId"
                                element={
                                  <Suspense>
                                    <PipelineStoreShow />
                                  </Suspense>
                                }
                              />
                            </Route>
                            <Route
                              path="show/:pipelineId/automation/:pipelineStageId"
                              element={
                                // <Layout action="edit" resource="automation">
                                <Suspense>
                                  <PipelineAutomationEdit />
                                </Suspense>
                                // </Layout>
                              }
                            />

                            <Route
                              path=":id/:stageId"
                              element={
                                // <Layout
                                //   action="show"
                                //   resource="pipeline_stages"
                                // >
                                <Suspense>
                                  <StagesShow />
                                </Suspense>
                                // </Layout>
                              }
                            />
                          </Route>
                          <Route
                            path="groups"
                            element={
                              // <Layout action="list" resource="groups">
                              <Suspense>
                                <TagList>
                                  <Outlet />
                                </TagList>
                              </Suspense>
                              // </Layout>
                            }
                          >
                            <Route index element={null} />
                            <Route
                              path="show/:id"
                              element={
                                // <Layout action="show" resource="groups">
                                <Suspense>
                                  <TagShow>
                                    <Outlet />
                                  </TagShow>
                                </Suspense>
                                // </Layout>
                              }
                            />
                            <Route
                              path="edit/:id"
                              element={
                                // <Layout action="edit" resource="groups">
                                <Suspense>
                                  <TagEdit>
                                    <Outlet />
                                  </TagEdit>
                                </Suspense>
                                // </Layout>
                              }
                            />
                          </Route>
                          <Route path="files">
                            <Route
                              index
                              element={
                                // <Layout action="list" resource="files">
                                <Suspense>
                                  <FileList />
                                </Suspense>
                                // </Layout>
                              }
                            />
                          </Route>
                        </Route>
                        <Route
                          path="account"
                          element={
                            // <Layout action="list" resource="accounts">
                            <Suspense>
                              <AccountShow />
                            </Suspense>
                            // </Layout>
                          }
                        >
                          <Route index element={null} />
                        </Route>
                        <Route
                          path="pipelines/show/:pipelineId/settings"
                          element={
                            <Layout action="edit" resource="pipelines">
                              <Suspense>
                                <PipelineEdit />
                              </Suspense>
                            </Layout>
                          }
                        />

                        <Route
                          path="pipelines/show/:pipelineId/settings/:stageId"
                          element={
                            <Layout action="show" resource="pipeline_stages">
                              <Suspense>
                                <StagesShow />
                              </Suspense>
                            </Layout>
                          }
                        />
                        <Route
                          path="pipelines/show/:pipelineId/settings/:stageId/automation"
                          element={
                            <Layout action="edit" resource="automation">
                              <Suspense>
                                <PipelineAutomationEdit />
                              </Suspense>
                            </Layout>
                          }
                        />
                        <Route
                          path="pipelines/show/:pipelineId"
                          element={
                            // <Layout action="show" resource="pipeline_items">
                            <Suspense>
                              <CanAccess
                                resource="pipelines"
                                action="list"
                                fallback={t("module.noAccessTitle")}
                              >
                                <PipelineShow>
                                  <Outlet />
                                </PipelineShow>
                              </CanAccess>
                            </Suspense>
                            // </Layout>
                          }
                        >
                          <Route
                            path="items/:itemId"
                            element={
                              // <Layout action="show" resource="pipeline_items">
                              <Suspense>
                                <PipelineItemShow />
                              </Suspense>
                              // </Layout>
                            }
                          />
                          <Route
                            path="items/create"
                            element={
                              // <Layout action="create" resource="pipeline_items">
                              <Suspense>
                                <PipelineItemCreate />
                              </Suspense>
                              // </Layout>
                            }
                          />
                          <Route
                            path="items/edit/:itemId"
                            element={
                              // <Layout action="edit" resource="pipeline_items">
                              <Suspense>
                                <PipelineItemEdit />
                              </Suspense>
                              // </Layout>
                            }
                          />
                        </Route>

                        <Route
                          path="*"
                          element={
                            <Layout action="show" resource="pipeline_items">
                              <ErrorComponent />
                            </Layout>
                          }
                        />
                        <Route path="team-members">
                          <Route
                            index
                            element={
                              // <Layout action="list" resource="team-members">
                              <Suspense>
                                <TeamMembersList />
                              </Suspense>
                              // </Layout>
                            }
                          />
                        </Route>
                        <Route path="sales-kpi">
                          <Route
                            index
                            element={
                              <Suspense>
                                <SalesKPIList />
                              </Suspense>
                            }
                          />
                        </Route>
                        <Route
                          path="automations"
                          element={
                            <Suspense>
                              <AutomationsList>
                                <Outlet />
                              </AutomationsList>
                            </Suspense>
                          }
                        >
                          <Route index element={null} />
                          <Route
                            path="create"
                            element={
                              <Suspense>
                                <AutomationsCreate>
                                  <Outlet />
                                </AutomationsCreate>
                              </Suspense>
                            }
                          />

                          <Route
                            path="show/:id"
                            element={
                              <Suspense>
                                <ContactShow>
                                  <Outlet />
                                </ContactShow>
                              </Suspense>
                            }
                          />
                        </Route>
                        <Route
                          path="automations/edit/:id"
                          element={
                            <Suspense>
                              <AutomationsEdit />
                            </Suspense>
                          }
                        />
                      </Route>
                    </Route>

                    <Route
                      path="team/create"
                      element={
                        <Authenticated
                          key="authenticated-outer"
                          fallback={<CatchAllNavigate to="/login" />}
                        >
                          <CreateTeam />
                        </Authenticated>
                      }
                    />
                    <Route
                      path="/account/setup"
                      element={
                        <Authenticated
                          key="authenticated-outer"
                          fallback={<CatchAllNavigate to="/login" />}
                        >
                          <AccountSetup />
                        </Authenticated>
                      }
                    />
                    <Route
                      element={
                        <Authenticated
                          key="authenticated-outer"
                          fallback={<Outlet />}
                        >
                          <NavigateToResource />
                        </Authenticated>
                      }
                    >
                      <Route
                        path="/login"
                        element={
                          <AuthPage
                            type="login"
                            wrapperProps={{
                              style: {
                                background: "url('/images/authpage-login.jpg')",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                                bottom: "0px",
                                left: "0px",
                              },
                            }}
                            title=""
                            formProps={{
                              initialValues: { email: "", password: "" },
                            }}
                          />
                        }
                      />
                      <Route
                        path="/register"
                        element={
                          <RegisterPage
                            wrapperProps={{
                              style: {
                                background:
                                  "url('/images/authpage-register.jpg')",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                                bottom: "0px",
                                left: "0px",
                              },
                            }}
                            title={t(
                              "pages.register.welcome",
                              "Sign up now and try Tellie for free and without obligation."
                            )}
                          />
                        }
                      />
                      <Route
                        path="/forgot-password"
                        element={
                          <AuthPage
                            type="forgotPassword"
                            wrapperProps={{
                              style: {
                                background:
                                  "linear-gradient(45deg, #3f96ff 30%, #6fC6ff 90%)",
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                                bottom: "0px",
                                left: "0px",
                              },
                            }}
                            title=""
                          />
                        }
                      />
                    </Route>
                    <Route
                      path="/update-password"
                      element={
                        <AuthPage
                          type="updatePassword"
                          title={
                            <ThemedTitleV2
                              collapsed={false}
                              text="CRM"
                              icon={<AppIcon />}
                            />
                          }
                        />
                      }
                    />
                    <Route path="/verify" element={<VerifyPage />} />
                  </Routes>

                  <RefineKbar />
                  <UnsavedChangesNotifier />
                  <DocumentTitleHandler
                    handler={({ resource, action, params }) => {
                      return (
                        `${params?.id ? `#${params.id}` : ""} ${
                          action !== "list"
                            ? capitalizeEveryWord(action ?? "")
                            : ""
                        } ${capitalizeEveryWord(resource?.name ?? "")}`.trim() +
                        " | Tellie"
                      );
                    }}
                  />
                </Refine>
              </DevtoolsProvider>
            </AntdApp>
          </ColorModeContextProvider>
        </RefineKbarProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
